import React from 'react'
import './header-secondary.css'

const HeaderSecondary = ({ title, description }) => {
    return (
        <div className="header-secondary">
            <div className="container">
                <h2>{title}</h2>
                <div>{description}</div>
            </div>
        </div>
    )
}

export default HeaderSecondary;