import HeaderSecondary from "../../header-secondary/header-secondary";

const Rules = () => {
    return (
        <section>
            <HeaderSecondary
                title='Пользовательское соглашение сервиса Just-Tests.ru'
                description=''/>
            <div className="pt-2 pb-4">
                <div className="container py-4">
                    Дата публикации: 15 февраля 2022 г. <br/>
                    Текущая версия доступна по адресу: https://just-tests.ru/legal/rules.<br/>

                    1. Общие положения<br/>
                    1.1. Индивидуальный предприниматель Скорняков Никита Михайлович (ОГРНИП 322183200002181) (далее —
                    «Администратор») предлагает пользователю сети Интернет (далее – Пользователь) - использовать сервис
                    на условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение», «ПС»).
                    Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке,
                    предусмотренном п. 1.4 Соглашения.<br/>
                    1.2. Администратор предлагает Пользователям доступ к сервису, включая тесты ПДД, ГТН, ГИМС и т. д.<br/>
                    1.3. Использование сервиса Just-Tests.ru регулируется настоящим Соглашением, а также следующими
                    документами, которые являются неотъемлемой частью настоящего соглашения: Политикой
                    конфиденциальности (https://just-tests.ru/legal/confidential), Публичной офертой
                    (https://just-tests.ru/legal/public_ofer). Администратор может изменить Соглашение без какого-либо
                    специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети
                    Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией
                    Соглашения. Действующая редакция ПС всегда находится на странице по адресу
                    https://just-tests.ru/legal/rules.<br/>
                    1.4. Начиная использовать какой-либо сервис/его отдельные функции, либо пройдя процедуру
                    регистрации, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких
                    оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения,
                    Пользователь не вправе использовать сервис Just-Tests.ru. В случае если Администратором были внесены
                    какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.3 Соглашения, с которыми
                    Пользователь не согласен, он обязан прекратить использование сервиса Just-Tests.ru.<br/>
                    2. Регистрация Пользователя. Учетная запись Пользователя<br/>
                    2.1. Для того чтобы воспользоваться сервисом Just-Tests.ru Пользователю необходимо пройти процедуру
                    регистрации, в результате которой для Пользователя будет создана уникальная учетная запись.
                    Пользователь, достигший возраста 16 лет, вправе самостоятельно производить регистрацию учетной
                    записи и использовать сервис Just-Tests.ru в пределах правоспособности, установленной применимым
                    законодательством. В случае если Пользователь не достиг указанного возраста, а также в случаях,
                    когда этого требует применимое законодательство, использование сервиса допускается только с согласия
                    родителей или иных законных представителей.<br/>
                    2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по
                    вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии.
                    Если Пользователь предоставляет неверную информацию или у Администратора есть основания полагать,
                    что предоставленная Пользователем информация неполная или недостоверна, Администратор имеет право по
                    своему усмотрению заблокировать либо удалить учетную запись Пользователя и отказать Пользователю в
                    использовании своего сервиса.<br/>
                    2.2.1. При регистрации Пользователь может настроить Имя Пользователя, которое может сопровождать
                    опубликованные Пользователем материалы в рамках использования сервиса Just-Tests.ru, в том числе
                    комментарии, отзывы и т.п. (Имя Пользователя).
                    Если пользователь не настроил Имя Пользователя, то оно будет отображаться в формате: имя и фамилия,
                    указанные Пользователем в форме регистрации.<br/>
                    2.3. Подтверждение учетной записи<br/>
                    2.3.1. Администратор оставляет за собой право в любой момент потребовать от Пользователя
                    подтверждения данных, указанных при регистрации в учетной записи Пользователя и содержащих
                    информацию о Пользователе, а также иную информацию, связанную с использованием сервиса
                    Just-Tests.ru, доступную Пользователю после авторизации с использованием логина и пароля
                    Пользователя в сервисе Just-Tests.ru.<br/>
                    2.3.2. Для целей проверки заявленных Пользователем данных Администратор вправе запросить
                    подтверждающие документы (в частности - документы, удостоверяющие личность), непредставление
                    которых, по усмотрению Администратора, может быть приравнено к предоставлению недостоверной
                    информации и повлечь последствия, предусмотренные п. 2.2 Соглашения. В случае если данные
                    Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при
                    регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют
                    идентифицировать Пользователя, Администратор вправе отказать Пользователю в доступе к учетной записи
                    и использованию сервиса Just-Tests.ru.<br/>
                    2.4. Персональная информация Пользователя, определенная Политикой конфиденциальности и содержащаяся
                    в учетной записи Пользователя, хранится и обрабатывается Администратором в соответствии с условиями
                    Политики конфиденциальности (https://just-tests.ru/legal/confidential).<br/>
                    2.5. Средства для доступа к учетной записи Пользователя.<br/>
                    2.5.1. При регистрации имя учетной записи Пользователя соответствует электронному почтовому адресу,
                    указанному пользователем в соответствующей графе. Также Пользователь самостоятельно выбирает пароль
                    для доступа к учетной записи. Администратор вправе запретить использование определенных логинов, а
                    также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.).
                    Пользователь вправе использовать альтернативное средство авторизации, выбранное им из предложенных в
                    форме авторизации вариантов, в том числе с использованием учетных записей сторонних ресурсов.
                    Выбирая, альтернативное средство авторизации, Пользователь выражает согласие на получение данных о
                    Пользователе от сторонних информационных ресурсов на условиях Политики конфиденциальности
                    (https://just-tests.ru/legal/confidential). Пользователь обязан самостоятельно контролировать
                    надежность выбранных им средств доступа к стороннему ресурсу и сохранность учетных данных.<br/>
                    2.5.2 После осуществления Пользователем входа в учетную запись, данные учетной записи могут быть
                    автоматически сохранены в браузере устройства до завершения Пользователем работы под своей учетной
                    записью и не требовать дополнительного ввода средств доступа к учетной записи при каждом
                    использовании сервиса Just-Tests.ru. Автоматически сохраненные в браузере устройства данные учетной
                    записи Пользователя могут быть использованы для доступа к сервису Just-Tests.ru, включая сайт и
                    приложения.<br/>
                    2.6. Пользователь самостоятельно несет ответственность за безопасность (в том числе устойчивость к
                    угадыванию) выбранных им средств для доступа к учетной записи, а также самостоятельно обеспечивает
                    их конфиденциальность. Пользователь самостоятельно несет ответственность за все действия (а также их
                    последствия) в рамках или с использованием сервиса Just-Tests.ru под учетной записью Пользователя,
                    включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя
                    третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия в
                    рамках или с использованием сервиса Just-Tests.ru под учетной записью Пользователя считаются
                    произведенными самим Пользователем, за исключением случаев, когда Пользователь, в порядке,
                    предусмотренном п. 2.7., уведомил Администратора о несанкционированном доступе к сервису
                    Just-Tests.ru с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о
                    нарушении) конфиденциальности своих средств доступа к учетной записи.<br/>
                    2.7. Пользователь обязан немедленно уведомить Администратора о любом случае несанкционированного (не
                    разрешенного Пользователем) доступа к сервису Just-Tests.ru с использованием учетной записи
                    Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств
                    доступа к учетной записи. В целях безопасности Пользователь обязан самостоятельно осуществлять
                    безопасное завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой сессии
                    работы с сервисом Just-Tests.ru. Администратор не отвечает за возможную потерю или порчу данных, а
                    также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем
                    положений этой части Соглашения.<br/>
                    2.8. Использование Пользователем своей учетной записи.<br/>
                    2.8.1. Пользователь не вправе воспроизводить, повторять и копировать, продавать и перепродавать, а
                    также использовать для каких-либо коммерческих целей какие-либо части сервиса Just-Tests.ru (включая
                    контент, доступный Пользователю посредством сервисов), или доступ к ним, кроме тех случаев, когда
                    Пользователь получил такое разрешение от Администратора.<br/>
                    2.9. Прекращение регистрации. Администратор вправе заблокировать или удалить учетную запись
                    Пользователя, а также запретить доступ с использованием какой-либо учетной записи к сервису
                    Just-Tests.ru, и удалить любой контент без объяснения причин, в том числе в случае нарушения
                    Пользователем условий Соглашения.<br/>
                    2.10. Удаление учетной записи Пользователя.<br/>
                    2.10.1. Пользователь вправе в любой момент удалить свою учетную запись.<br/>
                    2.10.2. Удаление учетной записи осуществляется в следующем порядке:<br/>
                    2.10.2.1. учетная запись автоматически блокируется на срок один месяц, в течение которого доступ
                    Пользователя к своей учетной записи становится невозможен, при этом контент размещенный с помощью
                    такой учетной записи может быть удален;<br/>
                    2.10.2.2. если в течение указанного выше срока учетная запись Пользователя будет восстановлена
                    Пользователем, доступ к учетной записи для Пользователя восстановится, однако контент, размещенной с
                    ее помощью, может не подлежать восстановлению;<br/>
                    2.10.2.3. если в течение указанного выше срока учетная запись Пользователя не будет восстановлена,
                    весь контент, размещенный с ее использованием, будет автоматически удален, а логин будет доступен
                    для использования другим пользователям. С этого момента восстановление учетной записи, какой-либо
                    информации, относящейся к ней, доступа к сервису Just-Tests.ru с использованием этой учетной записи
                    - невозможны.<br/>
                    2.10.3. Приведенный в пп. 2.10.2.1., 2.10.2.2., 2.10.2.3. Соглашения (за исключением доступности
                    логина для использования другими пользователями) порядок применим также к запрету доступа с
                    использованием какой-либо учетной записи к определенным сервисам.<br/>
                    3. Общие положения об использовании и хранении<br/>
                    3.1. Администратор вправе устанавливать ограничения в использовании сервиса для всех Пользователей,
                    либо для отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка,
                    на котором предоставляется сервис и т.д.). Администратор может запретить автоматическое обращение к
                    своим сервисам, а также прекратить прием любой информации, сгенерированной автоматически (например,
                    почтового спама).
                    Администратор, в целях защиты оборудования Пользователя и собственного оборудования, вправе
                    ограничить прием любых сообщений и их доставку Пользователю, когда такие сообщения содержат
                    вредоносные программы или код, либо когда автоматические средства фильтрации и антивирусной защиты
                    сервиса Just-Tests.ru определили наличие таких вредоносных программ или кодов в указанных
                    сообщениях. Пользователь уведомлен и согласен с тем, что Администратор, в указанных целях, вправе
                    осуществлять анализ и исследование таких вредоносных программ и кодов, содержащихся в указанных
                    сообщениях, для улучшения качества работы автоматических средств фильтрации и антивирусной защиты
                    сервиса Just-Tests.ru.<br/>
                    3.2. Администратор вправе направлять Пользователю информационные сообщения. Администратор также
                    вправе направлять Пользователю сообщения рекламного характера при условии получения согласия
                    способом, соответствующим требованиям законодательства, либо при условии, что Пользователь не
                    отказался от получения сообщений рекламного характера в отношении соответствующего сервиса (при
                    регистрации, либо позднее с использованием соответствующей функциональности).
                    Пользователь выражает согласие Администратору на оповещение других Пользователей Сервиса о публичных
                    действиях Пользователя, в том числе о размещении им новых публикаций, о произведенных им действиях в
                    отношении Контента других Пользователей, а также иной активности, совершенной им в рамках Сервиса.<br/>
                    3.3. В целях повышения качества сервиса Just-Tests.ru и/или привлекаемые им для проведения опроса
                    лица вправе осуществлять сбор мнений и отзывов Пользователей по различным вопросам путем направления
                    информационного сообщения при очередном посещении Пользователем сервиса либо осуществления связи по
                    контактным данным, указанным Пользователем в учетной записи (посредством электронных писем).
                    Собранные мнения и отзывы могут быть использованы для формирования статистических данных, которые
                    могут быть использованы в сервисе Just-Tests.ru. Отзывы, предоставленные Пользователем при
                    проведении опроса, также могут быть опубликованы Администратором в сервисе Just-Tests.ru, как с
                    указанием имени (логина) Пользователя, так и без указания. При оставлении отзывов Пользователь
                    обязуется руководствоваться требованиями настоящего Соглашения, в том числе требованиями,
                    установленными п. 5 настоящего Соглашения.<br/>
                    4. Контент Пользователя<br/>
                    4.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого
                    Пользователем контента требованиям действующего законодательства, включая ответственность перед
                    третьими лицами в случаях, когда размещение Пользователем того или иного контента или содержание
                    контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права
                    авторов, иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им нематериальные
                    блага.<br/>
                    4.2. Пользователь признает и соглашается с тем, что Администратор не обязан просматривать контент
                    любого вида, размещаемый и/или распространяемый Пользователем посредством сервиса Just-Tests.ru, а
                    также то, что Администратор имеет право (но не обязанность) по своему усмотрению отказать
                    Пользователю в размещении и/или распространении им контента или удалить любой контент, который
                    доступен посредством сервиса Just-Tests.ru. Пользователь осознает и согласен с тем, что он должен
                    самостоятельно оценивать все риски, связанные с использованием контента, включая оценку надежности,
                    полноты или полезности этого контента.<br/>
                    5. Условия использования сервиса Just-Tests.ru<br/>
                    5.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия,
                    связанные с использованием Сервиса, в том числе, если такие действия приведут к нарушению прав и
                    законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сервиса.<br/>
                    5.2. При использовании сервиса Just-Tests.ru Пользователь не вправе:<br/>
                    5.2.1. загружать, посылать, передавать или любым другим способом размещать и/или распространять
                    контент, который является незаконным, вредоносным, клеветническим, оскорбляет нравственность,
                    демонстрирует (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной
                    собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому,
                    половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или
                    организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет
                    собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных
                    услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ
                    или их аналогов, взрывчатых веществ или иного оружия;<br/>
                    5.2.2. нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в
                    любой форме;<br/>
                    5.2.3. выдавать себя за другого человека или представителя организации и/или сообщества без
                    достаточных на то прав, в том числе за сотрудников сервиса Just-Tests.ru, за модераторов форумов, за
                    владельца сайта, а также применять любые другие формы и способы незаконного представительства других
                    лиц в сети, а также вводить пользователей или Администратора в заблуждение относительно свойств и
                    характеристик каких-либо субъектов или объектов;<br/>
                    5.2.4. загружать, посылать, передавать или любым другим способом размещать и/или распространять
                    контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным
                    отношениям;<br/>
                    5.2.5. загружать, посылать, передавать или любым другим способом размещать и/или распространять не
                    разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих
                    адресов электронной почты, схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы
                    интернет-заработка и e-mail-бизнесов, «письма счастья», а также использовать сервис Just-Tests.ru
                    для участия в этих мероприятиях, или использовать сервис Just-Tests.ru, исключительно для
                    перенаправления пользователей на страницы других доменов;<br/>
                    5.2.6. загружать, посылать, передавать или любым другим способом размещать и/или распространять
                    какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы,
                    предназначенные для нарушения, уничтожения либо ограничения функциональности любого компьютерного
                    или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа,
                    а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины,
                    пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете,
                    а также размещения ссылок на вышеуказанную информацию;<br/>
                    5.2.7. несанкционированно собирать и хранить персональные данные других лиц;<br/>
                    5.2.8. нарушать нормальную работу веб-сайта и сервиса Just-Tests.ru;<br/>
                    5.2.9. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых
                    Соглашением;<br/>
                    5.2.10. другим образом нарушать нормы законодательства, в том числе нормы международного права.<br/>
                    6. Исключительные права на содержание сервисов и контент<br/>
                    6.1. Все объекты, доступные при помощи сервиса Just-Tests.ru, в том числе элементы дизайна, текст,
                    графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие
                    объекты (далее – содержание сервисов), а также любой контент, размещенный на сервисе Just-Tests.ru,
                    являются объектами исключительных прав Администратора, Пользователей и других правообладателей.<br/>
                    6.2. Использование контента, а также каких-либо иных элементов сервисов возможно только в рамках
                    функционала, предлагаемого тем или иным сервисом. Никакие элементы содержания сервиса Just-Tests.ru,
                    а также любой контент, размещенный на сервисе Just-Tests.ru, не могут быть использованы иным образом
                    без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе:
                    воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и
                    т.д. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями
                    использования сервиса Just-Tests.ru.
                    Использование Пользователем элементов содержания сервисов, а также любого контента для личного
                    некоммерческого использования, допускается при условии сохранения всех знаков охраны авторского
                    права, смежных прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или
                    псевдонима) автора/наименования правообладателя в неизменном виде, сохранении соответствующего
                    объекта в неизменном виде. Исключение составляют случаи, прямо предусмотренные законодательством РФ
                    или пользовательскими соглашениями сервиса Just-Tests.ru.<br/>
                    7. Сайты и контент третьих лиц<br/>
                    7.1. Сервис Just-Tests.ru может содержать ссылки на другие сайты в сети Интернет (сайты третьих
                    лиц). Указанные третьи лица и их контент не проверяются Администратором на соответствие тем или иным
                    требованиям (достоверности, полноты, законности и т.п.). Администратор не несет ответственность за
                    любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает
                    доступ с использованием сервисов, в том числе, за любые мнения или утверждения, выраженные на сайтах
                    третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их
                    использования Пользователем.<br/>
                    7.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или
                    некоммерческого характера, размещенная на Сайте, не является одобрением или рекомендацией данных
                    продуктов (услуг, деятельности) со стороны Администратора, за исключением случаев, когда на это
                    прямо указывается на ресурсе Just-Tests.ru.<br/>
                    8. Реклама на сервисе Just-Tests.ru<br/>
                    8.1. Администратор несет ответственность за рекламу, размещенную им на сервисе Just-Tests.ru, в
                    пределах, установленных законодательством РФ.<br/>
                    9. Отсутствие гарантий, ограничение ответственности<br/>
                    9.1. Пользователь использует сервис Just-Tests.ru на свой собственный риск. Сервис предоставляется
                    «как есть». Администратор не принимает на себя никакой ответственности, в том числе за соответствие
                    сервисов целям Пользователя;<br/>
                    9.2. Администратор не гарантирует, что: сервисы соответствуют/будут соответствовать требованиям
                    Пользователя; сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты,
                    которые могут быть получены с использованием сервисов, будут точными и надежными и могут
                    использоваться для каких-либо целей или в каком-либо качестве (например, для установления и/или
                    подтверждения каких-либо фактов); качество какого-либо продукта, услуги, информации и пр.,
                    полученных с использованием сервисов, будет соответствовать ожиданиям Пользователя;<br/>
                    9.3. Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и
                    руководства к действию и т.д.), доступ к которым Пользователь получает с использованием сервиса
                    Just-Tests.ru, Пользователь может использовать на свой собственный страх и риск и самостоятельно
                    несет ответственность за возможные последствия использования указанных информации и/или материалов,
                    в том числе за ущерб, который это может причинить компьютеру Пользователя или третьим лицам, за
                    потерю данных или любой другой вред;<br/>
                    9.4. Администратор не несет ответственности за любые виды убытков, произошедшие вследствие
                    использования Пользователем сервиса Just-Tests.ru или отдельных частей/функций сервиса;<br/>
                    9.5. При любых обстоятельствах ответственность Администратора в соответствии со статьей 15
                    Гражданского кодекса России ограничена 10 000 (десятью тысячами) рублей РФ и возлагается на него при
                    наличии в его действиях вины.<br/>
                    10. Иные положения<br/>
                    10.1. Настоящее Соглашение представляет собой договор между Пользователем и Администратором
                    относительно порядка использования сервиса и заменяет собой все предыдущие соглашения между
                    Пользователем и Администратором.<br/>
                    10.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской
                    Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с
                    законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых
                    настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской
                    Федерации, по нормам российского права. Везде по тексту настоящего Соглашения, если явно не указано
                    иное, под термином «законодательство» понимается как законодательство Российской Федерации, так и
                    законодательство места пребывания Пользователя.<br/>
                    10.3. В отношении услуг, оказываемых в рамках настоящего Соглашения на безвозмездной основе, нормы о
                    защите прав потребителей, предусмотренные законодательством Российской Федерации, не могут быть
                    применимыми к отношениям между Пользователем и Администратором.<br/>
                    10.4. Ничто в Соглашении не может пониматься как установление между Пользователем и Администратором
                    агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного
                    найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.<br/>
                    10.6. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут
                    признаны недействительными или не имеющими юридической силы, это не оказывает влияния на
                    действительность или применимость остальных положений Соглашения.<br/>
                    10.7. Бездействие со стороны Администратора в случае нарушения Пользователем либо иными
                    пользователями положений Соглашений не лишает Администратора права предпринять соответствующие
                    действия в защиту своих интересов позднее, а также не означает отказа Администратора от своих прав в
                    случае совершения в последующем подобных либо сходных нарушений.<br/>
                    10.8. Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть
                    предоставлено Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной
                    версии Соглашения и версии Соглашения на ином языке, применяются положения русскоязычной версии
                    настоящего Соглашения.
                </div>
            </div>
        </section>
    )
}

export default Rules;