import React, { Component } from 'react';

import './app.css';

import RequestService from '../../services/api/requestService';
import Header from '../header/header';
import MainPage from '../pages/main-page';
import Footer from '../footer/footer';
import Categories from '../pages/categories-page';
import TestPage from '../pages/test-page';
import AboutUsPage from '../pages/about-us';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../pages/not-found';
import Legal from "../pages/legal";
import Confidential from "../pages/legal/confidential";
import Rules from "../pages/legal/rules";
import CookiesPolicy from "../pages/legal/cookies-policy";
import MobileAgrGims from "../pages/legal/mobile-agreement-gims";
import MobileAgrGtn from "../pages/legal/mobile-agreement-gtn";
import MobileAgrPdd from "../pages/legal/mobile-agreement-pdd";
import GimsApp from "../pages/gims-app";
import PddApp from "../pages/pdd-app";
import Posts from "../pages/posts";
import Post from "../pages/post";
import GtnApp from "../pages/gtn-app";

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exams: [],
            isLoading: false,
            isError: false,
            errorMessage: '',
        }
    }

    apiService = new RequestService()

    componentDidMount() {
        this.getCategories();
    }

    getCategories() {
        this.apiService.getExams().then((res) => {
            if (res) {
                this.setState({
                    exams: res.exams,
                    isError: false
                })
            } else {
                this.setState({
                    isError: true
                })
            }
        })

    }

    render() {
        const state = this.state
        return (
            <div className="app">
                <Router>
                    <Header {...state} />
                    <Switch>
                        <Route path='/' exact component={() => <MainPage {...state} />} />
                        <Route path='/gims' exact component={Categories} />
                        <Route path='/gtn' exact component={Categories} />
                        <Route path='/naks' exact component={Categories} />
                        <Route path='/pdd' exact component={Categories} />
                        <Route path='*/cat_:id' exact component={Categories} />
                        <Route path='*/test_:id' exact component={TestPage} />
                        {/*<Route path='/about-us' component={AboutUsPage}/>*/}
                        <Route path='/legal' exact component={Legal} />
                        <Route path='/legal/confidential' component={Confidential} />
                        <Route path='/legal/rules' component={Rules} />
                        <Route path='/legal/cookies_policy' component={CookiesPolicy} />
                        <Route path='/legal/mobile_agreement_gims' component={MobileAgrGims} />
                        <Route path='/legal/mobile_agreement_gtn' component={MobileAgrGtn} />
                        <Route path='/legal/mobile_agreement_pdd' component={MobileAgrPdd} />
                        <Route path='/pdd-app' component={PddApp} />
                        <Route path='/gims-app' component={GimsApp} />
                        <Route path='/gtn-app' component={GtnApp} />
                        {/* <Route path='/posts/:id' component={Post} />
                        <Route path='/posts' component={Posts} /> */}
                        <Route component={NotFoundPage} />
                    </Switch>
                </Router>
                <Footer />
            </div>
        );
    }
}