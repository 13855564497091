import https from 'https';

export default class requestService {
    constructor() {
        this._mainUrl = process.env.REACT_APP_API_URL;
    }

    async getResource(url) {
        try {
            const res = await fetch(this._mainUrl + url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                httpsAgent: new https.Agent({
                    rejectUnauthorized: false,
                }),
            });

            if (!res.ok) {
                throw new Error(`Could not fetch ${url}, status: ${res.status}`);
            }

            const some = await res.json();

            return some;
        }
        catch (ex) {
            console.error(ex);
            return 0;
        }
    }

    async getExams() {
        return this.getResource(process.env.REACT_APP_EXAM_URL);
    }

    async getCategoryTypes () {
        return this.getResource(process.env.REACT_APP_CATEGORY_TYPES_URL);
    }

    async getCategoriesByExam(examId, typeId = 1) {
        return this.getResource(process.env.REACT_APP_CATEGORY_BY_EXAM_URL+`?examId=${examId}&childType=${typeId}`);
    }

    async getCategories(id, type = 1) {
        return this.getResource(`/api/category/?parentId=${id}&childType=${type}`);
    }

    async getQuestions(id) {
        return this.getResource(`/api/questions/${id}`);
    }
}