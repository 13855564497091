import HeaderSecondary from "../header-secondary/header-secondary";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import GoogleImg from '../../images/google-btn.png'
import AppleImg from '../../images/apple-btn.svg'

const GtnApp = () => {
    if (/Android/i.test(navigator.userAgent)) {
        window.location.replace('https://play.google.com/store/apps/details?id=com.sgcode18.biletygtn');
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.location.replace('https://apps.apple.com/us/app/%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D1%8B-%D0%B3%D1%82%D0%BD-2022/id1613172595');
    }
    else {
        return (
            <section>
                <HeaderSecondary
                title='Приложения ГТН'
                description='Скачивайте наши приложения'/>
                <div className="pt-2 pb-4">
                    <div className="container py-4">
                        <div className="d-flex align-items-center ">
                            <a target="_blank" href='https://play.google.com/store/apps/details?id=com.sgcode18.biletygtn'><img height={80} alt='Доступно в Google Play' src={GoogleImg} /></a>
                            <a target="_blank" href='https://apps.apple.com/us/app/%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D1%8B-%D0%B3%D1%82%D0%BD-2022/id1613172595'><img height={56} alt='Скачивайте в AppStore' src={AppleImg} /></a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default GtnApp;