import React from 'react';
import HeaderImg from '../../images/header_icon.png'

import { Link } from 'react-router-dom';

import './header.css'

const Header = ({ exams }) => {
    let examElements = []
    if (exams && exams.length) {
        examElements = exams.map(el => {
            let url = '';

            switch (el.name) {
                case 'ГТН':
                    url = '/gtn';
                    break;
                case 'ГИМС':
                    url = '/gims';
                    break;
                case 'ПДД':
                    url = '/pdd';
                    break;
                case 'НАКС':
                    url = '/naks';
                    break;
            }

            return (<li key={el.id}><a className="dropdown-item" href={url}>{el.name}</a></li>)
        });
    }
    return (
        <header className="header">
            <div className="header__inner">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                            <img src={HeaderImg}></img>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="header__navbar collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                {/* <li className="nav-item">
                                    <Link to="/posts">
                                        <span className="nav-link">Статьи</span>
                                    </Link>
                                </li> */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
                                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Тесты
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {examElements}
                                    </ul>
                                </li>
                                {/*<li className="nav-item">
                                    <Link to="/about-us">
                                        <span className="nav-link" >О нас</span>
                                    </Link>
                                </li>*/}
                                <li className="nav-item">
                                    <Link to="/legal">
                                        <span className="nav-link">Информация</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;