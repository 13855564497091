import RequestService from '../api/requestService';
import {CategoryTypesEnum} from '../enums/categoryTypesEnum';

export default class categoryTaker {
    requestService = new RequestService();

    async getPageData(parentId, currentTypeId = null) {
        var nextTypeId = CategoryTypesEnum.Category;
        var result;

        if (currentTypeId === CategoryTypesEnum.Category) {
            nextTypeId = CategoryTypesEnum.Theme;
        } else if (currentTypeId === CategoryTypesEnum.Theme) {
            nextTypeId = CategoryTypesEnum.Ticket;
        }

        if (nextTypeId === CategoryTypesEnum.Category) {
            result = await this.requestService.getCategoriesByExam(parentId);
        } else {
            result = await this.requestService.getCategories(parentId, nextTypeId);
        }

        if (!result || !result.category.length) {
            if (nextTypeId === CategoryTypesEnum.Category) {
                nextTypeId = CategoryTypesEnum.Theme;
            } else {
                nextTypeId = CategoryTypesEnum.Ticket;
            }

            result = await this.requestService.getCategories(parentId, nextTypeId);
        }

        if(!result || !result.category.length) {
            nextTypeId = CategoryTypesEnum.Ticket;

            result = await this.requestService.getCategories(parentId, nextTypeId);
        }

        if(result.category.length === 1 && nextTypeId === CategoryTypesEnum.Theme) {
            nextTypeId = CategoryTypesEnum.Ticket;

            result = await this.requestService.getCategories(result.category[0].id, nextTypeId);
        }

        return result;
    }
}