import HeaderSecondary from "../../header-secondary/header-secondary";

const CookiePolicy = () => {
    return (
        <section>
            <HeaderSecondary
                title='Политика использования файлов cookie'
                description=''/>
            <div className="pt-2 pb-4">
                <div className="container py-4">
                    Дата публикации: 15 февраля 2022 г. <br/>

                    Текущая версия доступна по адресу: https://just-tests.ru/legal/cookies_policy.<br/>

                    Политика использования файлов cookie (далее — «Политика») применяется в дополнение к общей Политике
                    конфиденциальности, которая распространяется на продукты и сервисы (далее — «Сервисы»)
                    Индивидуального Предпринимателя Скорнякова Никиты Михайловича (ОГРНИП 322183200002181) и/или его
                    аффилированных лиц (далее — «Администратор»). Политика описывает типы файлов cookie, цели их
                    использования и способы, с помощью которых можно от них отказаться.<br/>
                    1. Что такое файлы cookie и для чего их использует Администратор<br/>
                    Файлы cookie — это небольшие фрагменты данных со служебной информацией о посещении сайта, которые
                    сервер отправляет на ваше устройство. Файлы cookie запоминают информацию о ваших предпочтениях,
                    позволяют в более удобном для вас режиме просматривать посещенные сайты в течение определенного
                    времени. Например, благодаря файлам cookie Сервисы предлагают вам просматривать информацию на том
                    языке, которым вы обычно пользуетесь.
                    Администратор использует файлы cookie для того, чтобы:
                    помогать вам оставаться авторизованным в Сервисах;
                    улучшать качество вашей работы с Сервисами;
                    показывать приоритетную для вас информацию;
                    сохранять ваши настройки рекламных предпочтений и безопасного поиска;
                    отображать рекламу, которая может вас заинтересовать;
                    анализировать статистику использования Сервисов.
                    На основании данных, полученных с помощью файлов cookie, Администратор разрабатывает наиболее
                    полезный функционал для Сервисов, которыми вы пользуетесь, проводит статистические и маркетинговые
                    исследования, исправляет ошибки в Сервисах и тестирует новые функции для повышения
                    производительности Сервисов, персонализирует их и показывает наиболее релевантную для вас
                    информацию.<br/>
                    2. Какие файлы cookie использует Администратор<br/>
                    Администратор использует различные типы файлов cookie. Основная задача в их использовании - это
                    обеспечить удобство вашей работы с Сервисами, поддерживать высокий уровень безопасности в их работе,
                    вести подсчет аудитории, помогать в проведении оплат, переходах по страницам и прочее. Все файлы
                    cookie Администратора относятся к одному из следующих типов:<br/>
                    (i) Технические файлы cookie, которые необходимы для правильной работы и предоставления полного
                    функционала Сервисов. Они устанавливаются автоматически и обеспечивают возможность аутентификации и
                    последующей авторизации в Сервисах.<br/>
                    (ii) Аналитические и маркетинговые файлы cookie, которые позволяют:
                    распознавать пользователей, подсчитывать их количество и собирать информацию, например, о
                    произведенных операциях, посещенных страницах и просмотрах на Сервисах;
                    идентифицировать ваше аппаратное или программное обеспечение, например, тип браузера или устройство
                    (с помощью cookie device_id);<br/>
                    собирать информацию о том, как вы взаимодействуете с Сервисами, например, приобретены ли услуга или
                    продукт;
                    обеспечивать релевантность рекламы, повышать эффективность рекламных кампаний.<br/>
                    (iii) Прочие файлы cookie выполняют различные служебные функции и делают удобнее работу с сайтами и
                    сервисами Администратора. Эти файлы помогают запомнить состояние страницы, выбрать регион, сохранить
                    персональные предпочтения, например, такие настраиваемые элементы сайта, как размер текста, шрифт и
                    прочее.<br/>
                    Администратор может применять веб-маяки (пиксельные теги) для доступа к файлам cookie, ранее
                    размещенным на вашем устройстве. Их использование необходимо для анализа ваших действий при работе с
                    Сервисами путем доступа и использования файлов cookie, хранящихся на Вашем устройстве, для сбора
                    статистики о работе самих Сервисов или продуктов и утилит Администратора.
                    Инструмент веб-аналитики Яндекс.Метрика, который используется Администратором, позволяет собирать
                    обезличенную информацию об источниках трафика, посещаемости сайта и оценивать эффективность рекламы.
                    Для учета посетителей Яндекс.Метрика использует анонимные идентификаторы браузеров, которые
                    сохраняются в файлах cookie. Подробнее о файлах cookie Яндекс.Метрики можно прочитать на странице
                    https://yandex.ru/support/metrica/general/cookie-usage.html.
                    Инструмент веб-аналитики Google Analytics, который используется Администратором, позволяет собирать
                    обезличенную информацию об источниках трафика, посещаемости сайта и оценивать эффективность рекламы.
                    Для учета посетителей Google Analytics использует анонимные идентификаторы браузеров, которые
                    сохраняются в файлах cookie. Подробнее о файлах cookie Google Analytics можно прочитать на странице
                    https://policies.google.com/technologies/partner-sites?hl=en-US.
                    Отключение некоторых указанных типов файлов cookie может привести к тому, что использование
                    отдельных разделов или функций Сервисов станет невозможным. Например, доступ к разделам Сервисов,
                    для которых нужна авторизация, обеспечивается техническими файлами cookie и без их использования
                    сайт может работать некорректно.<br/>
                    3. Как долго файлы cookie хранятся на вашем устройстве<br/>
                    Администратор использует сессионные файлы cookie, чтобы вам было удобнее работать с Сервисами. Срок
                    действия сессионных файлов cookie истекает в конце сессии (когда вы закрываете страницу или окно
                    браузера).
                    Администратор также может использовать файлы cookie, которые сохраняются в течение более длительного
                    периода, например, чтобы запомнить ваши предпочтения на Сервисах, такие как язык или местоположение.
                    Срок хранения данных зависит от типа файлов cookie. Такие файлы cookie будут автоматически удалены
                    после того, как выполнят свою задачу.
                    Администратор использует информацию, содержащуюся в файлах cookie в течение сроков и на условиях,
                    указанных в настоящей Политике, а также в Политике конфиденциальности.<br/>
                    4. Управление файлами cookie<br/>
                    При первом посещении Сервисов во всплывающем окне (либо с помощью другого технического решения) вам
                    может быть предложено выбрать типы файлов cookie, которые будут записаны на вашем устройстве . При
                    этом, технические файлы cookie устанавливаются автоматически при загрузке страницы, если иное не
                    указано в настройках браузера. Если вы одобрили использование файлов cookie, но потом захотели
                    изменить свое решение, то сделать это можно самостоятельно удалив сохраненные файлы в Вашем
                    браузере.
                    Администратор не требует обязательного согласия на установку файлов cookie на ваше устройство при
                    использовании Сервисов. Если вы не хотите, чтобы файлы cookie сохранялись на вашем устройстве,
                    отключите эту опцию в настройках браузера. Сохраненные файлы cookie также можно удалить в любое
                    время в системных настройках браузера. Вы можете изменить настройки браузера, чтобы принимать или
                    отклонять по умолчанию все файлы cookie либо файлы cookie с определенных сайтов, включая Сервисы
                    Администратора.
                    Если Вы одобрили использование файлов cookie на одном из сервисов Администратора, это будет
                    означать, что такое согласие дано вами для использования файлов cookie на всех Сервисах
                    Администратора.<br/>
                    5. Кто, кроме Администратора, имеет доступ к информации, содержащейся в файлах cookie<br/>
                    Информация, которая собрана с помощью файлов cookie, размещенных на вашем устройстве, хранится в
                    соответствии с применимым законодательством согласно условиям разделов 7 и 8 Политики
                    конфиденциальности: https://just-tests.ru/legal/confidential.
                    Партнеры Администратора также могут собирать информацию о пользователях с помощью файлов cookie или
                    пиксельных тегов в рамках использования Сервисов. Использование файлов cookie и других технологий
                    позволяет Администратору и его партнерам анализировать активность при использовании Сервисов,
                    подсчитывая количество посещений или показывая рекламу, адаптированную под услуги Сервиса.
                    Информация, собранная с помощью файлов cookie, размещенных на вашем устройстве, может быть передана
                    и доступна Администратору и/или партнерам на условиях Политики конфиденциальности. Использование вне
                    Сервисов собранной информации может быть предметом отдельных пользовательских соглашений, доступных
                    на сайтах партнеров. Администратор и/или партнеры могут также предоставить вам возможность
                    отказаться от персонализации рекламы, в соответствии с законодательством и правилами, применимыми к
                    таким продуктам и предложениям.


                </div>
            </div>
        </section>
    )
}

export default CookiePolicy;