import { Link } from 'react-router-dom';
import './app-section.css'

const AppSection = ({ link }) => {
    if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return (
            <div className="download-section py-4">
                <div className="container d-flex justify-content-center">
                    <Link target="_blank" className='download-section__link' to={link}>Скачать приложение</Link>
                </div>
            </div>
        );
    } else {
        return <></>
    }
}

export default AppSection;