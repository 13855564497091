import React from 'react';
import Card from '../card/card';
import './section-tests.css';

const SectionTests = ({ tests, isError }) => {
    let elements = [];

    if (!isError) {
        elements = tests.map((item) => {
            const { id } = item;
            return (
                <div key={id} className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                    <Card {...item} />
                </div>
            )
        })

        return (
            <div className="section-tests container">
                <h3 className="mb-4 pb-xl-3">Наши тесты</h3>
                <div className="row section-tests__inner justify-content-center">
                    {elements}
                </div>
            </div>
        )
    } else {
        return (
            <div className="section-tests container">
                <h3 className="mb-4 pb-xl-3">Наши тесты не загрузились..</h3>
                <div className="">
                    Похоже что-то пошло не так.. Пожалуйста, <a href="mailto: sgcode18@gmail.com">напишите нам</a> и мы решим проблему!
                </div>
            </div>
        )
    }


}

export default SectionTests;