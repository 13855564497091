import HeaderSecondary from "../../header-secondary/header-secondary";

const MobileAgrPdd = () => {
    return (
        <section>
            <HeaderSecondary
                title='Лицензионное соглашение на использование программы «Билеты ПДД 2023» для мобильных устройств'
                description=''/>
            <div className="pt-2 pb-4">
                <div className="container py-4">
                    Дата публикации: 15 февраля 2022 г. <br/>
                    Текущая версия доступна по адресу: https://just-tests.ru/legal/mobile_agreement_PDD.<br/>

                    Перед использованием программы, пожалуйста, ознакомьтесь с условиями нижеследующего лицензионного
                    соглашения.
                    Любое использование Вами программы означает полное и безоговорочное принятие Вами условий настоящего
                    лицензионного соглашения.
                    Если Вы не принимаете условия лицензионного соглашения в полном объёме, Вы не имеете права
                    использовать программу в каких-либо целях.<br/>

                    1. Общие положения<br/>
                    1.1. Настоящее Лицензионное соглашение («Лицензия») устанавливает условия использования программы
                    «Билеты ПДД 2023» для мобильных устройств («Программа») и заключено между любым лицом, использующим
                    Программу («Пользователь»), и Индивидуальным предпринимателем Скорняковым Никитой Михайловичем
                    (ОГРНИП 322183200002181), являющимся правообладателем исключительного права на Программу
                    («Правообладатель»).<br/>
                    1.2. Копируя Программу, устанавливая её на свое мобильное устройство или используя Программу любым
                    образом, Пользователь выражает свое полное и безоговорочное согласие со всеми условиями Лицензии.<br/>
                    1.3. Использование Программы разрешается только на условиях настоящей Лицензии. Если Пользователь не
                    принимает условия Лицензии в полном объёме, Пользователь не имеет права использовать Программу в
                    каких-либо целях. Использование Программы с нарушением (невыполнением) какого-либо из условий
                    Лицензии запрещено.<br/>
                    1.4. Использование Программы Пользователем на условиях настоящей Лицензии в личных некоммерческих
                    целях осуществляется безвозмездно. Использование Программы на условиях и способами, не
                    предусмотренными настоящей Лицензией, возможно только на основании отдельного соглашения с
                    Правообладателем.<br/>
                    1.5. Пользуясь Программой, Пользователь соглашается с тем, что неотъемлемыми частями настоящей
                    Лицензии являются следующие документы, условия которых в полной мере распространяются на
                    использование Программы:
                    «Пользовательское соглашение сервиса Just-Tests.ru», размещенное в сети Интернет по адресу:
                    https://just-tests.ru/legal/rules.
                    «Политика конфиденциальности», размещенная в сети Интернет по адресу:
                    https://just-tests.ru/legal/confidential.
                    Указанные документы (в том числе любые из их частей) могут быть изменены Правообладателем в
                    одностороннем порядке без какого-либо специального уведомления, новая редакция документов вступает в
                    силу с момента их опубликования, если иное не предусмотрено новыми редакциями документов.<br/>
                    1.6. К настоящей Лицензии и всем отношениям, связанным с использованием Программы, подлежит
                    применению право Российской Федерации и любые претензии или иски, вытекающие из настоящей Лицензии
                    или использования Программы, должны быть поданы и рассмотрены в суде по месту нахождения
                    Правообладателя.<br/>
                    1.7. Правообладатель может предоставить Пользователю перевод настоящей Лицензии с русского на другие
                    языки, однако в случае противоречия между условиями Лицензии на русском языке и ее переводом,
                    юридическую силу имеет исключительно русскоязычная версия Лицензии.
                    2. Права на Программу<br/>
                    2.1. Исключительное право на Программу принадлежит Правообладателю.<br/>
                    3. Лицензия<br/>
                    3.1. Правообладатель безвозмездно, на условиях простой (неисключительной) лицензии, предоставляет
                    Пользователю непередаваемое право использования Программы на территории всех стран мира следующими
                    способами:<br/>
                    3.1.1. Применять Программу по прямому функциональному назначению, в целях чего произвести ее
                    копирование и установку (воспроизведение) на мобильное (-ые) устройство (-ва) Пользователя.
                    Пользователь вправе произвести установку Программы на неограниченное число мобильных устройств.<br/>
                    3.1.2. Воспроизводить и распространять Программу в некоммерческих целях (безвозмездно).<br/>
                    3.2. Программа создана с использованием библиотек:<br/>
                    Android Jetpack Libs – Copyright © Google. Указанное программное обеспечение может использоваться на
                    условиях лицензионного соглашения MIT, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу:
                    https://github.com/PacktPublishing/Android-Jetpack-Architecture-Components<br/>
                    Accompanist Libs – Copyright © Google. Указанное программное обеспечение может использоваться на
                    условиях лицензионного соглашения Apache 2.0, текст которого доступен для ознакомления в Программе,
                    а также доступен для ознакомления по адресу: https://github.com/google/accompanist/blob/main/LICENSE
                    Paging3 – Copyright © Google. Указанное программное обеспечение может использоваться на условиях
                    лицензионного соглашения Apache 2.0, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу:
                    https://github.com/googlecodelabs/android-paging/blob/master/LICENSE<br/>
                    Coil – Copyright © Coil-Kt. Указанное программное обеспечение может использоваться на условиях
                    лицензионного соглашения Apache 2.0, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу: https://github.com/coil-kt/coil/blob/main/LICENSE.txt<br/>
                    Timber – Copyright © JakeWharton. Указанное программное обеспечение может использоваться на условиях
                    лицензионного соглашения Apache 2.0, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу: https://github.com/JakeWharton/timber/blob/trunk/LICENSE.txt<br/>
                    LottieAnimations – Copyright © Airbnb. Указанное программное обеспечение может использоваться на
                    условиях лицензионного соглашения Apache 2.0, текст которого доступен для ознакомления в Программе,
                    а также доступен для ознакомления по адресу:
                    https://github.com/airbnb/lottie-android/blob/master/LICENSE<br/>
                    Konfetti – Copyright © DanielMartinus. Указанное программное обеспечение может использоваться на
                    условиях лицензионного соглашения ISC, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу: https://github.com/DanielMartinus/Konfetti/blob/main/LICENSE<br/>
                    Compose Settings – Copyright © Alorma. Указанное программное обеспечение может использоваться на
                    условиях лицензионного соглашения MIT, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу: https://github.com/alorma/Compose-Settings/blob/main/LICENSE<br/>
                    ACRA – Copyright © acra. Указанное программное обеспечение может использоваться на условиях
                    лицензионного соглашения Apache 2.0, текст которого доступен для ознакомления в Программе, а также
                    доступен для ознакомления по адресу:https://github.com/ACRA/acra/blob/master/LICENSE<br/>
                    4. Ограничения<br/>
                    4.1. За исключением использования в объемах и способами, прямо предусмотренными настоящей Лицензией
                    или законодательством РФ, Пользователь не имеет права изменять, декомпилировать, дизассемблировать,
                    дешифровать и производить иные действия с Программой, имеющие целью получение информации о
                    реализации алгоритмов, используемых в Программе, создавать производные произведения с использованием
                    Программы, а также осуществлять (разрешать осуществлять) иное использование Программы без
                    письменного согласия Правообладателя.<br/>
                    4.2. Пользователь не имеет право воспроизводить и распространять Программу в коммерческих целях (в
                    том числе за плату), в том числе в составе сборников программных продуктов, без письменного согласия
                    Правообладателя.<br/>
                    4.3. Пользователь не имеет права распространять Программу в виде, отличном от того, в котором он ее
                    получил, без письменного согласия Правообладателя.<br/>
                    4.4. Программа должна использоваться (в том числе распространяться) под наименованием: «Билеты ПДД
                    2023». Пользователь не вправе изменять наименование Программы, изменять и/или удалять знак охраны
                    авторского права (copyright notice) или иные указания на Правообладателя.<br/>
                    5. Условия использования отдельных функций Программы<br/>
                    5.1. Выполнение отдельных функций Программы возможно только при наличии доступа к сети Интернет.
                    Пользователь самостоятельно получает и оплачивает такой доступ на условиях и по тарифам своего
                    оператора связи или провайдера доступа к сети Интернет.<br/>
                    5.4. Все данные об использовании Программы, передаваемые в соответствии с настоящей Лицензией,
                    сохраняются и обрабатываются в соответствии с Политикой конфиденциальности
                    (https://just-tests.ru/legal/confidential).<br/>
                    6. Ответственность по Лицензии<br/>
                    6.1. Программа предоставляется на условиях «как есть» (as is). Правообладатель не предоставляет
                    никаких гарантий в отношении безошибочной и бесперебойной работы Программы или отдельных её
                    компонентов и/или функций, соответствия Программы конкретным целям и ожиданиям Пользователя, не
                    гарантирует достоверность, точность, полноту и своевременность данных Программы, а также не
                    предоставляет никаких иных гарантий, прямо не указанных в настоящей Лицензии.<br/>
                    6.2. Правообладатель не несет ответственности за какие-либо прямые или косвенные последствия
                    какого-либо использования или невозможности использования Программы и/или ущерб, причиненный
                    Пользователю и/или третьим сторонам в результате какого-либо использования, неиспользования или
                    невозможности использования Программы или отдельных её компонентов и/или функций, в том числе из-за
                    возможных ошибок или сбоев в работе Программы, за исключением случаев, прямо предусмотренных
                    законодательством.<br/>
                    6.3. Пользователь настоящим уведомлен и соглашается, что при использовании Программы Правообладателю
                    в автоматическом режиме анонимно (без привязки к Пользователю) передается следующая информация:
                    IP-адрес устройства Пользователя, тип операционной системы мобильного устройства Пользователя,
                    статистика использования функций Программы.<br/>
                    6.4. Все вопросы и претензии, связанные с использованием/невозможностью использования Программы, а
                    также возможным нарушением Программой законодательства и/или прав третьих лиц, должны направляться
                    на электронный почтовый адрес support@just-tests.ru.<br/>
                    7. Обновления/новые версии Программы<br/>
                    7.1. Действие настоящей Лицензии распространяется на все последующие обновления/новые версии
                    Программы. Соглашаясь с установкой обновления/новой версии Программы, Пользователь принимает условия
                    настоящей Лицензии для соответствующих обновлений/новых версий Программы, если обновление/установка
                    новой версии Программы не сопровождается иным лицензионным соглашением.<br/>
                    8. Изменения условий настоящей Лицензии<br/>
                    8.1. Настоящее лицензионное соглашение может изменяться Правообладателем в одностороннем порядке.
                    Уведомление Пользователя о внесенных изменениях в условия настоящей Лицензии публикуется на
                    странице: https://just-tests.ru/legal/mobile_agreement_PDD. Указанные изменения в условиях
                    лицензионного соглашения вступают в силу с даты их публикации, если иное не оговорено в
                    соответствующей публикации.
                </div>
            </div>
        </section>
    )
}

export default MobileAgrPdd;