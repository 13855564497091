import React, { Component } from 'react';
import SecondaryCard from '../secondary-card/secondary-card';
import HeaderSecondary from '../header-secondary/header-secondary';
import CategoryTaker from '../../services/helpers/categoryTaker';
import { CategoryTypesEnum } from "../../services/enums/categoryTypesEnum";
import { MetaTags } from 'react-meta-tags';
import AppSection from '../app-section/app-section';

export default class Categories extends Component {
    constructor() {
        super();

        this.state = {
            id: 0,
            title: '',
            examName: '',
            description: '',
            categories: [],
            currentType: 0,
        }

        this.getCategories = this.getCategories.bind(this);
        this.getPageContent = this.getPageContent.bind(this);
    }

    categorytaker = new CategoryTaker();

    componentDidMount() {
        let [, id] = window.location.pathname.split('/').filter(e => e);
        if (id)
            id = id.replace('cat_', '');

        if (this.state.id != id) {
            this.getPageContent();
        }
    }

    // componentDidUpdate(prevProps) {
    //     const [, id] = window.location.pathname.split('/').filter(e => e);
    //     if (prevProps.id != id) {
    //         this.getPageContent();
    //     }
    // }

    getPageContent() {
        const pathnameArray = window.location.pathname.split('/').filter(e => e);
        let [path, id] = pathnameArray;
        if (id)
            id = id.replace('cat_', '');

        if (!id) {
            switch (path) {
                case 'gtn':
                    this.getCategories(1);
                    break;
                case 'gims':
                    this.getCategories(2);
                    break;
                case 'naks':
                    this.getCategories(3);
                    break;
                case 'pdd':
                    this.getCategories(4);
                    break;
            }
        } else {
            if (this.state.currentType) {
                this.getCategories(id, this.state.currentType);
            } else {
                this.getCategories(id);
            }
        }

        window.scrollTo(0, 0);
    }

    getCategories(id, currentCategoryType = 0) {
        if (id === 1 || id === 2 || id === 3 || id === 4) {
            this.categorytaker.getPageData(id).then((res) => {
                this.setState({
                    id: res.exam.id,
                    examName: res.exam.name,
                    description: res.exam.fullDescription,
                    categories: res.category,
                    currentType: res.category ? res.category[0].category_type : 0
                })
            })
        } else {
            this.categorytaker.getPageData(id, currentCategoryType).then((res) => {
                this.setState({
                    id: res.parentCategory.id,
                    examName: res.parentCategory.name,
                    description: res.parentCategory.description,
                    categories: res.category,
                    currentType: res.category ? res.category[0].category_type : 0
                })
            })
        }
    }

    render() {
        const { categories, currentType } = this.state;
        const pathnameArray = window.location.pathname.split('/').filter(e => e);
        const [path, subpath] = pathnameArray;

        const cards = categories.map((item) => {
            const { id } = item;

            let url = '';

            if (path != 'pdd' && path != 'gims')
                url = currentType === CategoryTypesEnum.Ticket ? `test_${id}` : `cat_${id}`;
            else
                url = currentType === CategoryTypesEnum.Theme ? `test_${id}` : `cat_${id}`;

            return (
                <div key={id} className="col-xl-3 col-lg-4 col-sm-6 col-12">
                    <a className="no-decoration" href={`/${path}/${url}`}>
                        <SecondaryCard {...item} />
                    </a>
                </div>
            );
        })


        let metaTitle, metaDescription, metaKeywords;

        switch (path) {
            case 'pdd':
                if (subpath == 'cat_1012') {
                    metaTitle = 'Билеты ПДД категорий «A», «B», «M» (Бесплатные тесты ПДД: подготовка к экзамену для получения водительских прав 2023)';
                    metaDescription = 'Официальные билеты ПДД категорий «A», «B», «M» и подкатегорий «А1», «B1» для подготовки к экзамену в ГИБДД, актуальные на 2023 год';
                    metaKeywords = 'пдд категория в, пдд 2023 категория в, билеты пдд 2023 категории в, экзамен пдд категория в, пдд онлайн 2023 категория в, решать пдд категория в, решать билеты пдд категории в, экзамен пдд 2023 категория в, пдд 2023 решать категория в, билеты пдд онлайн категория в, билеты пдд 2023 решать категория в, пдд ав, онлайн билеты пдд 2023 категория в, ответы пдд категории в, бесплатно билеты пдд категории в, пдд билеты 2023 бесплатно категория в, ответы пдд 2023 категория в, пдд решать онлайн категория в';
                }
                else if (subpath == 'cat_1013') {
                    metaTitle = 'Билеты ПДД категорий «C», «D» (Бесплатные тесты ПДД: подготовка к экзамену для получения водительских прав 2023)';
                    metaDescription = 'Официальные билеты ПДД категорий «C», «D» и подкатегорий «C1», «D1» для подготовки к экзамену в ГИБДД, актуальные на 2023 год';
                    metaKeywords = 'пдд с, пдд сд, билеты пдд сд, билеты пдд категории с, пдд 2023 сд, билеты пдд 2023 сд, пдд категории сд, билеты пдд категории сд, пдд вс, решать пдд сд, решать билеты пдд сд, пдд категории сд 2023, билеты пдд категории сд 2023, пдд онлайн сд, решать пдд сд 2023, решать билеты пдд 2023 сд, пдд 2023 вс, билеты пдд вс';
                }
                else {
                    metaTitle = 'Билеты ПДД 2023 (Бесплатные тесты ПДД: подготовка к экзамену для получения водительских прав 2023)';
                    metaDescription = 'Официальные билеты ПДД для подготовки к экзамену в ГИБДД с комментариями, актуальные на 2023 год';
                    metaKeywords = 'пдд 2023 билеты решать онлайн, пдд билеты 2023 онлайн, пдд билеты онлайн 2023, пдд онлайн 2023 билеты, пдд 2023 онлайн билеты, пдд онлайн экзамен 2023, пдд экзамен 2023 онлайн, пдд экзамены 2023, пдд билеты 2023 самые новые экзаменационные онлайн, пдд тест 2023, пдд билеты, пдд экзамен, пдд экзамены, пдд решать билеты, пдд экзаменационные билеты, пдд экзамен билеты, пдд гаи';
                }
                break;
            case 'gims':
                metaTitle = 'Билеты ГИМС 2023 (Бесплатные тесты ГИМС: подготовка к экзамену для получения прав судоводителя 2023) ';
                metaDescription = 'Официальные билеты ГИМС для подготовки к экзамену на право управления гидроциклом, маломерным судном, маломерным судном особой конструкции и парусным судном в различных районах плавания (ВП, ВВП, МП, МТ), актуальные на 2023 год';
                metaKeywords = 'гимс, гимс суда, маломерные гимс, гимс маломерные суда, гимс маломерное судно, гимс 2023, билеты гимс, экзамен гимс, гимс билеты 2023, билеты гимс маломерных судов, билеты гимс маломерное судно, ответ гимс, билеты гимс для маломерных, билеты гимс с ответами, гимс билеты 2023 для маломерных, билеты гимс для маломерных судов 2023, экзаменационные билеты гимс, гимс онлайн, экзамен гимс 2023, билеты гимс для маломерных судов с ответами, вопросы гимс, экзаменационные билеты гимс 2023';
                break;
            case 'gtn':
                metaTitle = 'Билеты ГТН 2023 (Бесплатные тесты ГТН: подготовка к экзамену для получения водительских прав 2023) ';
                metaDescription = 'Официальные билеты ГосТехНадзора для подготовки к экзамену на право управления квадроциклом, снегоходом, трактором, погрузчиком, экскаватором, бульдозером и самоходными машинами, актуальные на 2023 год';
                metaKeywords = 'гостехнадзор экзамен, гостехнадзор билеты, гостехнадзор 2023, гостехнадзор самоходные машины, гостехнадзор экзаменационные билеты, гостехнадзор погрузчик, гостехнадзор билеты на трактор, гостехнадзор права тракториста, билеты гостехнадзор 2023, гостехнадзор билеты пдд, тракторист машинист гостехнадзор, гтн экзамен, экзамен гостехнадзор билеты, самоходная техника гостехнадзор, гостехнадзор экзамен на трактор, экзамен гостехнадзор 2023, гостехнадзор пдд 2023';
                break;
        }

        const { description, examName } = this.state;

        let title = 'Выберите ';
        if (currentType === CategoryTypesEnum.Ticket) {
            title += 'билет:'
        }
        if (currentType === CategoryTypesEnum.Category) {
            title += 'категорию:'
        }
        if (currentType === CategoryTypesEnum.Theme) {
            title += 'тему:'
        }

        return (
            <>
                <MetaTags>
                    <title>{metaTitle}</title>
                    <meta name='description' content={metaDescription} />
                    <meta name='keywords' content={metaKeywords} />
                </MetaTags>
                <section>
                    <HeaderSecondary
                        title={examName}
                        description={description} />
                    <AppSection link={`/${path}-app`}/>
                    <div className="pt-2 pb-4">
                        <div className="container">
                            <h3 className="my-4">{title}</h3>
                            <div className="row">
                                {cards}
                            </div>
                        </div>
                    </div>
                </section>
            </>)
    }
}
