import React from "react";

import './test-reload-button.css';

const TestReloadButton = ({ resetTest }) => {
    return (
        <button className="white-button" onClick={resetTest}>
            <div className="white-button__icon">
                <svg width="304" height="304" viewBox="0 0 304 304" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M273.193 62.099C245.08 25.376 202.332 4.314 155.911 4.314C123.275 4.314 92.327 14.799 66.411 34.637C57.034 41.816 48.551 50.117 41.166 59.279L34.581 21.98C33.86 17.901 29.966 15.173 25.891 15.898L6.196 19.374C4.237 19.72 2.496 20.83 1.355 22.459C0.214002 24.089 -0.231997 26.104 0.114002 28.063L15.76 116.692C16.403 120.33 19.567 122.89 23.137 122.89C23.57 122.89 24.009 122.852 24.45 122.774L113.08 107.128C117.159 106.408 119.882 102.518 119.162 98.439L115.685 78.744C115.339 76.785 114.23 75.044 112.6 73.903C110.97 72.762 108.955 72.317 106.996 72.662L70.063 79.182C75.258 73.042 81.138 67.441 87.687 62.428C107.449 47.301 131.048 39.306 155.934 39.306C191.353 39.306 223.962 55.369 245.403 83.375C263.669 107.235 271.549 136.781 267.593 166.569C263.636 196.358 248.316 222.823 224.455 241.088C204.637 256.259 181.075 264.278 156.316 264.278C151.32 264.278 146.254 263.942 141.259 263.279C111.471 259.323 85.006 244.004 66.74 220.142C55.622 205.619 48.15 188.483 45.131 170.586C44.8 168.625 43.703 166.875 42.082 165.722C40.462 164.569 38.448 164.109 36.487 164.438L16.766 167.765C12.682 168.454 9.93 172.324 10.618 176.408C14.581 199.903 24.377 222.383 38.948 241.417C62.896 272.701 97.595 292.787 136.65 297.974C143.184 298.842 149.815 299.282 156.358 299.282C188.844 299.282 219.748 288.768 245.727 268.88C277.012 244.932 297.098 210.233 302.285 171.177C307.475 132.121 297.143 93.383 273.193 62.099Z" fill="#231F20" />
                    </g>
                </svg>
            </div>
            Перезапуск
        </button >
    )
}

export default TestReloadButton;