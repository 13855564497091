import React from "react";
import { Link } from "react-router-dom";
import HeaderSecondary from "../header-secondary/header-secondary";

const NotFoundPage = () => {
    return (
        <section>
            <HeaderSecondary
                title='Ошибка 404'
                description='' />
            <div className="pt-2 pb-4">
                <div className="container">
                    <h3 className="my-4"></h3>
                    <div>
                        Такой страницы нет, и, возможно, никогда не существовало. <br/>
                        Вернемся на <a className="d-inline" href='/'>главную</a>.
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NotFoundPage;