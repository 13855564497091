import HeaderSecondary from "../header-secondary/header-secondary";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import GoogleImg from '../../images/google-btn.png'
import AppleImg from '../../images/apple-btn.svg'

const GimsApp = () => {
    if (/Android/i.test(navigator.userAgent)) {
        window.location.replace('https://play.google.com/store/apps/details?id=com.sgcode18.biletygims');
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.location.replace('https://apps.apple.com/us/app/%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D1%8B-%D0%B3%D0%B8%D0%BC%D1%81-%D1%80%D1%84-2022/id1627569789');
    }
    else {
        return (
            <section>
                <HeaderSecondary
                    title='Приложения ГИМС'
                    description='Скачивайте наши приложения' />
                <div className="pt-2 pb-4">
                    <div className="container py-4">
                        <div className="d-flex align-items-center ">
                            <a target="_blank" href='https://play.google.com/store/apps/details?id=com.sgcode18.biletygims&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img height={80} alt='Доступно в Google Play' src={GoogleImg} /></a>
                            <a target="_blank" href='https://apps.apple.com/us/app/%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D1%8B-%D0%B3%D0%B8%D0%BC%D1%81-%D1%80%D1%84-2022/id1627569789'><img height={56} alt='Скачивайте в AppStore' src={AppleImg} /></a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default GimsApp;