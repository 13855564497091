import React, { Component } from 'react'
import { MetaTags } from 'react-meta-tags';
import SectionTests from '../section-tests/section-tests'
import SmartPhoneImg from '../../images/smartphone.png'
import './styles/section.css'

export default class MainPage extends Component {
    render() {
        const { exams, isError } = this.props;
        return (
            <>
                <MetaTags>
                    <title>Just Tests - Бесплатный образовательный сервис для подготовки к экзаменам</title>
                    <meta name='description' content='Здесь Вы легко и быстро сможете подготовиться к сдаче экзамена для получения водительского удостоверения, удостоверения тракториста-машиниста и удостоверения судоводителя.' />
                    <meta name='keywords' content='Just Tests, Just-tests.ru' />
                </MetaTags>
                <section className="section">
                    <div className="section-header">
                        <div className="section-header__inner">
                            <div className="container">
                                <h1 className="section-header__title mb-3">Будь готов к экзамену!</h1>
                                <h2 className="section-header__secondary-title mb-xl-5">Все тесты бесплатно и всегда под рукой.</h2>
                            </div>
                        </div>
                    </div>

                    <SectionTests tests={exams} isError={isError} />

                    {/* <div className="section-mobile pt-4">
                    <div className="container">
                        <div className="d-inline-block section-mobile__img">
                            <img src={SmartPhoneImg}></img>
                        </div>
                        <div className="d-inline-block section-mobile__inner">
                            <h3 className="section-mobile__title">Или воспользуйтесь нашими <br /> удобными приложениями</h3>
                            <a className="btn btn-primary d-block" href="#">link</a>
                            <a className="btn btn-primary d-block" href="#">link</a>
                        </div>
                    </div>
                </div> */}
                </section>
            </>
        )
    }
}