import React from 'react';
import './footer.css'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className='container'>
                <div>
                    © Just Tests, {currentYear} <br />
                    <a href="mailto: support@just-tests.ru">support@just-tests.ru</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;