import React, {useState} from 'react';

export default function MobileAdv(props) {
    return (
        <div className="desktop-hide">
            {/*<!-- Yandex.RTB R-A-1712391-2 -->*/}
            <div id={"yandex_rtb_R-A-1712391-2-" + props.blockId}></div>
            <script>
                {
                    window.yaContextCb.push(() => {
                        // eslint-disable-next-line
                        Ya.Context.AdvManager.render({
                            renderTo: 'yandex_rtb_R-A-1712391-2-' + props.blockId,
                            blockId: 'R-A-1712391-2'
                        })
                    })
                }
            </script>
        </div>
    );
}