export default class Mapper {
    static mapQuestion(serverModel) {
        return {
            id: serverModel.id,
            num: serverModel.num_in_ticket,
            isAnswered: false,
            isTrue: false,
            text: serverModel.text,
            image: serverModel.image,
            comment: serverModel.comment,
            answers: serverModel.answers.map((el, ind) => {
                return {
                    id: ind,
                    text: el.text,
                    selected: false,
                    isTrue: el.is_correct
                }
            })
        };
    }

    static mapQuestionsList(serverCollection) {
        return {
            ticketName: serverCollection.categoryId.name,
            ticketDescription: serverCollection.categoryId.description ?? '',
            currentQuestionNum: 1,
            questions: serverCollection.ticket.map(el => this.mapQuestion(el))
        }
    }
}