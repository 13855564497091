import React from "react";

import './test-question-block.css';

const TestQuestionBlock = ({currentQuestion, setAnswer, nextQuestion, isLast, result, resetTest, isAllAnswered}) => {
    let answers, footer;
    if (currentQuestion) {
        answers = currentQuestion.answers.map(el => {
            let liClasses = '';

            if (currentQuestion.isAnswered) {
                if (el.selected) {
                    liClasses += ' selected';
                }

                if (el.isTrue) {
                    liClasses += ' correct';
                } else {
                    liClasses += ' incorrect';
                }
            }

            return (
                <li
                    className={liClasses}
                    key={el.id + currentQuestion.num * 4}
                    onClick={() => {
                        if (!currentQuestion.isAnswered) setAnswer(currentQuestion.num, el.id)
                    }}>
                    <span className="answer-item">
                        {el.text}
                    </span>
                </li>
            )
        });

        if (currentQuestion.isAnswered) {
            footer =
                <div className="question__footer">
                    {
                        currentQuestion.comment &&
                        <div className="pb-4">
                            <div className="question-comment__header">Комментарии к вопросу:</div>
                            <div className="question-comment__text">{currentQuestion.comment}</div>
                        </div>
                    }
                    {
                        isAllAnswered && isLast &&
                        <button className="btn"
                                onClick={nextQuestion}>Результат</button>
                    }
                    {
                        !isLast &&
                        <button className="btn"
                                onClick={nextQuestion}>Следующий вопрос</button>
                    }

                </div>
        }
    } else {
        currentQuestion = {};
    }

    if (result) {
        const percent = Math.round(result.correctNum / (result.incorrectNum + result.correctNum) * 100),
            isDone = percent >= 80,
            color = isDone ? '#8DF4A9' : '#FF7C7C'
        return (
            <div className="question">
                <div className='questions__main'>
                    <div className="result">
                        <div className="result__info">
                            <div className="result__info--percent">
                                <svg class="scoreCircle" viewBox="0 0 32 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill={color}></circle>
                                    <path
                                        d="M30.5 9.5C30.5 13.5 24.8366 7 16 7C7.16344 7 1.5 14 1.5 9.5C2.5 6.5 7.16344 0 16 0C24.8366 0 29 6 30.5 9.5Z"
                                        fill="#2E2E33"></path>
                                </svg>
                                <span>
                                    {percent} %
                                </span>
                            </div>
                            <div className="test-result test-result--vertical">
                                <div className="test-result--correct">
                                    <span>{result.correctNum}</span> Верных
                                </div>
                                <div className="test-result--incorrect">
                                    <span>{result.incorrectNum}</span> Неверных
                                </div>
                            </div>
                        </div>
                        <div className="result__description">
                            <h3>{isDone ? 'Отличный результат!' : percent == 10 ? 'Вы напугали деда!' : 'Стоит попробовать еще'}</h3>
                            <div className="result-bar">
                                <span className="result-bar__part correct"
                                      style={{width: `calc(${percent}% - 1px)`}}></span>
                                <span className="result-bar__part incorrect"
                                      style={{width: `calc(${100 - percent}% - 1px)`}}></span>
                            </div>
                            <div className="result__need-for-access">
                                <b>80%</b>
                                <div>Необходимо для сдачи</div>
                            </div>
                            <div className="result__action-zone">
                                <button className="result__reload-button" onClick={resetTest}>Начать заново</button>
                                <button className="result__reload-button ms-xl-2" onClick={() => {
                                    window.history.back()
                                }}>Назад
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="question">
                {
                    currentQuestion.image &&
                    <div className="question__image">
                        <img src={process.env.REACT_APP_API_URL + currentQuestion.image}></img>
                    </div>
                }
                <div className="questions__main">
                    <h4>{currentQuestion.text ?? ''}</h4>
                    <ul className="questions__answers">
                        {answers ?? ''}
                    </ul>
                </div>
                {footer}
            </div>
        )
    }
}

export default TestQuestionBlock;