import React from 'react';
import './card.css'
import {Link} from 'react-router-dom';

const Card = (props) => {
    const {id, name, image, shortDescription, year, disabled = false} = props;

    let classNames = "test-card";
    let url = '';

    switch (name) {
        case 'ГТН':
            url = 'gtn';
            break;
        case 'ГИМС':
            url = 'gims';
            break;
        case 'ПДД':
            url = 'pdd';
            break;
        case 'НАКС':
            url = 'naks';
            break;
    }

    if (disabled) classNames += " disabled";

    return (
        <Link className="no-decoration" to={`/${url}/`}>
            <div className={classNames}>
                <div className="test-card__year">{year}</div>
                <div className="test-card__icon mb-4" dangerouslySetInnerHTML={{__html: image}}>
                </div>
                <div>
                    <h4 className="test-card__name">{name}</h4>
                    <div className="underline-block"></div>
                    <div className="test-card__description">{shortDescription}</div>
                </div>
            </div>
        </Link>
    )
}

export default Card;