import React, {Component} from 'react'

import HeaderSecondary from '../header-secondary/header-secondary'
import TestResultBlock from '../test-result-block/test-result-block'
import TestReloadButton from '../test-reload-button/test-reload-button'
import TestQuestionBlock from '../test-question-block/test-question-block'
import RequestService from '../../services/api/requestService';
import Mapper from '../../services/helpers/mapper';

import './styles/test.css'
import MobileAdv from "../advertising/mobile-adv";
import DesktopAdv from "../advertising/desktop-adv";

export default class TestPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketName: '',
            ticketDescription: '',
            questions: [],
            currentQuestionNum: 1
        }

        this.resetTest = this.resetTest.bind(this);
        this.setAnswer = this.setAnswer.bind(this);
        this.loadQuestion = this.loadQuestion.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.getQuestions = this.getQuestions.bind(this);
    }

    apiService = new RequestService();

    componentDidMount() {
        const pathnameArray = window.location.pathname.split('/').filter(e => e);
        let [, id] = pathnameArray;
        id = id.replace('test_', '');

        this.getQuestions(id);
    }

    getQuestions(id) {
        this.apiService.getQuestions(id).then(res => {

            this.setState({
                ...Mapper.mapQuestionsList(res)
            })
        });
    }


    resetTest() {
        this.setState(({questions}) => {
            const emptyQuestions = questions.map((el) => {
                el.isAnswered = false;
                el.isTrue = false;
                el.answers.map(e => {
                    e.selected = false;
                    return e;
                });
                return el;
            });

            return {
                questions: emptyQuestions,
                currentQuestionNum: 1
            }
        });
    }

    setAnswer(questionNum, answerNum) {
        this.setState(({questions}) => {
            const index = questions.findIndex(e => e.num === questionNum);
            const currentQuestion = questions[index];
            const selectedAnswer = currentQuestion.answers.find(e => e.id === answerNum);

            currentQuestion.isAnswered = true;
            currentQuestion.isTrue = selectedAnswer.isTrue;
            selectedAnswer.selected = true;

            const result = [...questions.slice(0, index), currentQuestion, ...questions.slice(index + 1)];

            return {
                questions: result
            }
        })
    }

    loadQuestion(targetQuestionNum) {
        if (targetQuestionNum > this.state.questions.length) {
            this.setState({
                currentQuestionNum: 0,
                result: {
                    correctNum: 1,
                    incorrectNum: 9
                }
            })
        } else {
            this.setState({
                currentQuestionNum: targetQuestionNum,
                result: null
            });
        }
    }

    nextQuestion() {
        this.loadQuestion(this.state.currentQuestionNum + 1);
    }

    getRandom() {
        return Math.floor(Math.random() * 10000);
    }

    render() {
        const {ticketName, questions, currentQuestionNum} = this.state;
        let questionNumList, correctAnswersNum, incorrectAnswersNum, currentQuestion, result;

        questionNumList = questions.map((el) => {
            return (
                {
                    num: el.num,
                    answered: el.isAnswered,
                    isTrue: el.isTrue
                }
            );
        });
        correctAnswersNum = questionNumList.filter(e => e.isTrue && e.answered).length;
        incorrectAnswersNum = questionNumList.filter(e => !e.isTrue && e.answered).length;

        currentQuestion = questions.find(e => e.num === currentQuestionNum);

        if (currentQuestionNum === 0) {
            result = {
                correctNum: correctAnswersNum,
                incorrectNum: incorrectAnswersNum,
            }
        }

        const header = 'Билет ' + ticketName;

        return (
            <section>
                <HeaderSecondary title={header}/>
                <div className="tests container py-xl-5 py-3">
                    <aside>
                        <MobileAdv key={this.getRandom() + '-' + currentQuestionNum}
                                   blockId={currentQuestionNum}/>
                        <TestResultBlock
                            correctNum={correctAnswersNum}
                            incorrectNum={incorrectAnswersNum}
                            currentQuestionNum={currentQuestionNum}
                            questions={questionNumList}
                            loadQuestion={this.loadQuestion}/>
                        <TestReloadButton resetTest={this.resetTest}/>
                        <DesktopAdv key={this.getRandom() + '-' + currentQuestionNum}
                                    blockId={currentQuestionNum}/>
                    </aside>
                    <section>
                        <TestQuestionBlock
                            currentQuestion={currentQuestion}
                            isLast={currentQuestionNum == questions.length}
                            result={result}
                            isAllAnswered={questions.length === (correctAnswersNum + incorrectAnswersNum)}
                            setAnswer={this.setAnswer}
                            nextQuestion={this.nextQuestion}
                            loadQuestion={this.loadQuestion}
                            resetTest={this.resetTest}/>
                    </section>
                </div>
            </section>
        )
    }
}