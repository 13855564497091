import React from "react";
import './test-result-block.css';

const TestResultBlock = ({ correctNum, incorrectNum, currentQuestionNum, questions, loadQuestion }) => {
    let numList;
    numList = questions.map((el) => {
        let classes = 'num-list__item';

        if (el.answered && el.isTrue) {
            classes += ' num-list__item--correct';
        } else if (el.answered && !el.isTrue) {
            classes += ' num-list__item--incorrect';
        }

        if (el.num === currentQuestionNum) {
            classes += ' active'
        }

        return (
            <li
                key={el.num}
                className={classes}
                onClick={() => loadQuestion(el.num)}>
                {el.num}
            </li>
        )
    });

    return (
        <div className="white-panel mb-3">
            <h4 className="mb-3">Ваш результат</h4>
            <div>
                <ul className="num-list">
                    {numList}
                </ul>
            </div>
            <div className="test-result">
                <div className="test-result--correct">
                    <b>{correctNum}</b> Верных
                </div>
                <div className="test-result--incorrect">
                    <b>{incorrectNum}</b> Неверных
                </div>
            </div>
        </div>
    );
}

export default TestResultBlock;