import HeaderSecondary from "../../header-secondary/header-secondary";

const Confidential = () => {
    return (
        <section>
            <HeaderSecondary
                title='Политика конфиденциальности'
                description=''/>
            <div className="pt-2 pb-4">
                <div className="container py-4">
                    Дата публикации: 15&nbsp;февраля 2022&nbsp;г.<br/>

                    Текущая версия доступна по&nbsp;адресу: https://just-tests.ru/legal/confidential.<br/>

                    1. Что регулирует настоящая политика конфиденциальности<br/>
                    Настоящая политика конфиденциальности (далее&nbsp;&mdash; Политика) действует в&nbsp;отношении всей
                    информации, включая персональные данные в&nbsp;понимании применимого законодательства
                    (далее&nbsp;&mdash; &laquo;Персональная информация&raquo;), которую Индивидуальный Предприниматель
                    Скорняков Никита Михайлович (ОГРНИП 322183200002181) и/или его аффилированные лица
                    (далее&nbsp;&mdash; &laquo;Администратор&raquo;), могут получить о&nbsp;Вас в&nbsp;процессе
                    использования Вами любых сайтов, программ, продуктов и/или сервисов Администратора (далее
                    вместе&nbsp;&mdash; &laquo;Сервисы&raquo;), информацию о&nbsp;которых&nbsp;Вы можете найти
                    на&nbsp;сайте just-tests.ru и&nbsp;других принадлежащих Администратору сайтах (далее
                    вместе&nbsp;&mdash; &laquo;Сайты&raquo;), а&nbsp;также в&nbsp;ходе исполнения Администратором&nbsp;/
                    его аффилированными лицами любых соглашений и&nbsp;договоров, заключенных с&nbsp;Вами в&nbsp;связи
                    с&nbsp;использованием Вами Сервисов. Администратор может также получать Персональную информацию
                    от&nbsp;своих партнеров (далее&nbsp;&mdash; &laquo;Партнеры&raquo;), сайты, программы, продукты или
                    сервисы которых&nbsp;Вы используете. В&nbsp;таких случаях передача Персональной информации возможна
                    только в&nbsp;случаях, установленных применимым законодательством, и&nbsp;осуществляется
                    на&nbsp;основании специальных договоров между Администратором и&nbsp;каждым из&nbsp;Партнеров.<br/>

                    Пожалуйста, обратите внимание, что использование любого из&nbsp;Сайтов и/или Сервисов может
                    регулироваться дополнительными условиями, которые могут вносить в&nbsp;настоящую Политику изменения
                    и/или дополнения, и/или иметь специальные условия в&nbsp;отношении персональной информации,
                    размещенные в&nbsp;соответствующих разделах документов для таких Сайтов и/или Сервисов.<br/>

                    2. Кто обрабатывает информацию<br/>
                    Для обеспечения использования Вами Сайтов и&nbsp;Сервисов Ваша Персональная информация собирается
                    и&nbsp;используется Администратором или его аффилированным лицом, предоставляющим соответствующий
                    Сервис в&nbsp;иных юрисдикциях. С&nbsp;информацией о&nbsp;том, какое лицо предоставляет тот или иной
                    Сервис, Вы&nbsp;можете ознакомиться в&nbsp;условиях использования соответствующего Сервиса.<br/>

                    3. Какова цель данной Политики<br/>
                    Защита Вашей Персональной информации и&nbsp;Вашей конфиденциальности чрезвычайно важны для
                    Администратора. Поэтому при использовании Вами Сайтов и&nbsp;Сервисов Администратор защищает
                    и&nbsp;обрабатывает Вашу Персональную информацию в&nbsp;строгом соответствии с&nbsp;применимым
                    законодательством.<br/>

                    Следуя нашим обязанностям защищать Вашу Персональную информацию, в&nbsp;этой Политике
                    мы&nbsp;хотели&nbsp;бы наиболее прозрачно проинформировать Вас о&nbsp;следующих моментах:<br/>

                    (a) зачем и&nbsp;как Администратор собирает и&nbsp;использует (&laquo;обрабатывает&raquo;) Вашу
                    Персональную информацию, когда&nbsp;Вы используете Сайты и/или Сервисы;<br/>

                    (b) какова роль и&nbsp;обязанности Администратора как юридического лица, принимающего решение
                    о&nbsp;том, зачем и&nbsp;как обрабатывать Вашу Персональную информацию;<br/>

                    &copy;&nbsp;какие инструменты&nbsp;Вы можете использовать для сокращения объема собираемой
                    Администратором Персональной информации о&nbsp;Вас;<br/>

                    (d) каковы Ваши права в&nbsp;рамках проводимой обработки Персональной информации.<br/>

                    4. Какую Персональную информацию о&nbsp;Вас собирает Администратор<br/>
                    Персональная информация, собранная в&nbsp;процессе работы Сайтов и/или предоставления Сервисов,
                    может различаться в&nbsp;зависимости от&nbsp;того, используете&nbsp;Вы для доступа к&nbsp;Сайтам
                    и/или Сервисам Вашу учетную запись или нет. В&nbsp;тех случаях, когда&nbsp;Вы осуществляете вход
                    в&nbsp;свою учетную запись, Персональная информация, собранная о&nbsp;Вас Администратором
                    во&nbsp;время использования Вами Сайтов и&nbsp;Сервисов, может быть сопоставлена и&nbsp;связана
                    с&nbsp;другой Персональной информацией, собранной Администратором в&nbsp;рамках использования Вами
                    Вашей учетной записи (например, данные о&nbsp;Вашей личности, контактные данные, возраст и&nbsp;пол,
                    если они были предоставлены Администратору). Администратор не&nbsp;проверяет предоставленную Вами
                    Персональную информацию за&nbsp;исключением случаев, предусмотренных пользовательским соглашением
                    или условиями использования отдельных сервисов, и&nbsp;не&nbsp;может судить
                    о&nbsp;ее&nbsp;достоверности, а&nbsp;также о&nbsp;том, обладаете&nbsp;ли вы&nbsp;достаточной
                    правоспособностью для предоставления Вашей Персональной информации. Тем не&nbsp;менее Администратор
                    исходит из&nbsp;того, что&nbsp;Вы предоставляете достоверную и&nbsp;достаточную Персональную
                    информацию, а&nbsp;также своевременно обновляете&nbsp;ее.<br/>

                    Администратор может собирать следующие категории Персональной информации о&nbsp;Вас во&nbsp;время
                    использования Вами Сайтов и&nbsp;Сервисов:<br/>

                    (i) Персональная информация, предоставленная Вами при регистрации (создании учетной записи), такая
                    как Ваше имя, номер телефона, адрес и&nbsp;возраст;<br/>

                    (ii) электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные
                    об&nbsp;идентификаторе браузера, информация об&nbsp;аппаратном и&nbsp;программном обеспечении,
                    данные сети wi-fi);<br/>

                    (iii) дата и&nbsp;время осуществления доступа к&nbsp;Сайтам и/или Сервисам;<br/>

                    (iv) информация о&nbsp;Вашей активности во&nbsp;время использования Сайтов и/или Сервисов (например,
                    информация о&nbsp;взаимодействии с&nbsp;другими пользователями);<br/>

                    (v) информация о&nbsp;геолокации;<br/>

                    (vi) иная информация о&nbsp;Вас, необходимая для обработки в&nbsp;соответствии с&nbsp;условиями,
                    регулирующими использование конкретных Сайтов и&nbsp;Сервисов Администратора;<br/>

                    (vii) информация о&nbsp;Вас, которую мы&nbsp;получаем от&nbsp;наших Партнеров в&nbsp;соответствии
                    с&nbsp;условиями соглашений, заключенных между Вами и&nbsp;соответствующим Партнером,
                    и&nbsp;соглашений, заключенных между Администратором и&nbsp;Партнером.<br/>

                    Также Администратор использует файлы cookie и&nbsp;веб-маяки (включая пиксельные теги) для сбора
                    Персональной информации и&nbsp;связывания такой личной информации с&nbsp;Вашим устройством
                    и&nbsp;веб-браузером (см. раздел 11&nbsp;настоящей Политики).<br/>

                    Администратор не&nbsp;собирает целенаправленно чувствительную персональную информацию (такую как
                    расовое происхождение, политические взгляды, информация о&nbsp;здоровье и&nbsp;биометрические
                    данные), за&nbsp;исключением случаев, предусмотренных пользовательскими соглашениями отдельных
                    Сервисов. Тем не&nbsp;менее&nbsp;Вы можете самостоятельно предоставить их&nbsp;Администратору,
                    и&nbsp;в&nbsp;таком случае Администратор будет обрабатывать их&nbsp;в&nbsp;рамках предоставления Вам
                    Сервисов. При этом&nbsp;Вы должны принимать во&nbsp;внимание, что Администратор не&nbsp;может
                    запросить Ваше согласие на&nbsp;такую обработку, поскольку не&nbsp;осведомлен заранее
                    о&nbsp;потенциально чувствительном характере Персональной информации, которую&nbsp;Вы можете
                    предоставить Администратору.<br/>

                    Администратор не&nbsp;собирает данные с&nbsp;целью составления &laquo;портрета&raquo; пользователя
                    в&nbsp;той степени, при которой это может существенно повлиять на&nbsp;Ваши права и&nbsp;свободы
                    в&nbsp;соответствии с&nbsp;применимым законодательством.<br/>

                    5. Какова правовая основа и&nbsp;цели обработки Вашей Персональной информации<br/>
                    Администратор не&nbsp;вправе обрабатывать Вашу Персональную информацию без достаточных
                    на&nbsp;то&nbsp;правовых оснований. Поэтому Администратор обрабатывает Вашу Персональную информацию
                    только в&nbsp;том случае, если:<br/>

                    (i) обработка необходима для выполнения договорных обязательств Администратора перед Вами, включая
                    обеспечение работы Сайтов и&nbsp;Сервисов;<br/>

                    (ii) обработка необходима для соблюдения установленных законодательством обязательств;<br/>

                    (iii) когда это предусмотрено применимым законодательством, обработка необходима для обеспечения
                    законных интересов Администратора в&nbsp;случае, если такая обработка не&nbsp;оказывает
                    существенного влияния на&nbsp;Ваши интересы, Ваши фундаментальные права и&nbsp;свободы. Обратите
                    внимание, что при обработке Вашей персональной информации на&nbsp;указанном основании, Администратор
                    всегда будет стремиться поддерживать баланс между своими законными интересами и&nbsp;защитой Вашей
                    конфиденциальности.<br/>

                    Администратор обрабатывает Вашу Персональную информацию для обеспечения своих законных интересов,
                    например, в&nbsp;следующих случаях:<br/>

                    (a) чтобы лучше понимать, как&nbsp;Вы взаимодействуете с&nbsp;нашими Сайтами и/или Сервисами;<br/>

                    (b) чтобы совершенствовать, менять, персонализировать или иным образом улучшать Сайты и&nbsp;Сервисы
                    в&nbsp;интересах всех пользователей;<br/>

                    &copy;&nbsp;чтобы предлагать Вам другие продукты и&nbsp;сервисы Администратора или других компаний,
                    которые, по&nbsp;нашему мнению, могут Вас заинтересовать (т.&nbsp;е. показывать Вам рекламу,
                    учитывающую Ваши интересы);<br/>

                    (iv) для конкретных целей либо в&nbsp;соответствии с&nbsp;требованием применимого законодательства
                    мы&nbsp;можем запросить Ваше отдельное согласие на&nbsp;обработку Вашей Персональной информации. Для
                    юрисдикций, где согласие признается отдельным правовым основанием, начало использования вами Сайтов
                    и/или Сервисов Администратор выражает Ваше согласие с&nbsp;такой обработкой. Настоящим
                    мы&nbsp;информируем Вас, а&nbsp;Вы подтверждаете, что у&nbsp;Вас нет никаких обязательств
                    по&nbsp;предоставлению нам какой-либо Персональной информации при использовании Вами Сайтов или
                    Услуг и&nbsp;основано исключительно на&nbsp;Вашей свободной воле. Вместе с&nbsp;тем&nbsp;Вы
                    осознаете, что без предоставления Персональной информации мы&nbsp;не&nbsp;сможем предоставить Вам
                    возможность воспользоваться Сервисами, а&nbsp;использование Вами Сайтов будет ограничено.<br/>

                    Администратор всегда обрабатывает Вашу Персональную информацию в&nbsp;определенных целях
                    и&nbsp;только ту&nbsp;Персональную информацию, которая имеет отношение к&nbsp;достижению таких
                    целей. В&nbsp;частности, мы&nbsp;обрабатываем Вашу Персональную информацию для следующих целей:<br/>

                    (i) предоставление Вам доступа к&nbsp;Сайтам и/или Сервисам;<br/>

                    (ii) предоставление доступа к&nbsp;Вашей учетной записи;<br/>

                    (iii) осуществление связи с&nbsp;Вами для направления Вам уведомлений, запросов и&nbsp;информации,
                    относящейся к&nbsp;работе Сайтов и&nbsp;Сервисов, выполнения соглашений с&nbsp;Вами и&nbsp;обработки
                    Ваших запросов и&nbsp;заявок;<br/>

                    (iv) персонализация рекламы и&nbsp;предложений с&nbsp;учетом Ваших предпочтений и&nbsp;другой
                    Персональной информации о&nbsp;Вас, доступной Администратору;<br/>

                    (v) повышение удобства использования Сайтов и&nbsp;Сервисов, в&nbsp;том числе для улучшения других
                    продуктов, приложений и&nbsp;Сервисов Администратора;<br/>

                    (vi) создание новых продуктов, утилит и&nbsp;предложений Администратора;<br/>

                    (vii) защита Ваших прав и&nbsp;прав Администратора;<br/>

                    (viii) сбор, обработка и&nbsp;представление статистических данных, больших данных и&nbsp;других
                    исследований.<br/>

                    6. Как Администратор защищает Вашу Персональную информацию<br/>
                    В&nbsp;большинстве случаев Персональная информация обрабатывается автоматически без доступа
                    к&nbsp;ней кого-либо из&nbsp;сотрудников Администратора. В&nbsp;случае если такой доступ
                    понадобится, то&nbsp;он&nbsp;может быть предоставлен только тем сотрудникам Администратора, которые
                    нуждаются в&nbsp;этом для выполнения своих задач. Для защиты и&nbsp;обеспечения конфиденциальности
                    данных все сотрудники должны соблюдать внутренние правила и&nbsp;процедуры в&nbsp;отношении
                    обработки Персональной информации. Они также должны следовать всем техническим
                    и&nbsp;организационным мерам безопасности, действующим для защиты Вашей Персональной информации.<br/>

                    Администратор также внедрил достаточные технические и&nbsp;организационные меры для защиты
                    Персональной информации от&nbsp;несанкционированного, случайного или незаконного уничтожения,
                    потери, изменения, недобросовестного использования, раскрытия или доступа, а&nbsp;также иных
                    незаконных форм обработки. Данные меры безопасности были реализованы с&nbsp;учетом современного
                    уровня техники, стоимости их&nbsp;реализации, рисков, связанных с&nbsp;обработкой и&nbsp;характером
                    Персональной информации.<br/>

                    7. Кто еще имеет доступ к&nbsp;Вашей Персональной информации и&nbsp;кому она может быть передана
                    7.1 Администратор может передавать Вашу Персональную информацию своим сотрудникам (в&nbsp;пределах,
                    указанных в&nbsp;разделе 6&nbsp;настоящей Политики). Администратор также может передавать Вашу
                    Персональную информацию своим аффилированным лицам. Обратите внимание, что уровень защиты
                    Персональной информации в&nbsp;некоторых странах может не&nbsp;совпадать с&nbsp;уровнем,
                    установленном в&nbsp;вашей юрисдикции, и, используя Сервисы Администратора, Вы&nbsp;соглашаетесь
                    на&nbsp;такую передачу.<br/>

                    Во&nbsp;всех случаях Персональная информация также будет обрабатываться только для целей, изложенных
                    в&nbsp;разделе 5&nbsp;настоящей Политики, если иное не&nbsp;установлено условиями использования
                    Сайтов и/или Сервисов.<br/>

                    7.2 Администратор также может передавать Персональную информацию третьим лицам для достижения целей,
                    указанных в&nbsp;разделе 5&nbsp;настоящей Политики. Обратите внимание, что уровень защиты
                    Персональной информации в&nbsp;некоторых странах может не&nbsp;совпадать с&nbsp;уровнем,
                    установленном в&nbsp;вашей юрисдикции, и, используя Сервисы Администратора, Вы&nbsp;соглашаетесь
                    на&nbsp;такую передачу.<br/>

                    К&nbsp;таким третьим лицам могут относиться:<br/>

                    (i) Партнеры, такие как владельцы сайтов и&nbsp;приложений, рекламные сети и&nbsp;другие партнеры,
                    предоставляющие Администратору услуги, связанные с&nbsp;размещением и&nbsp;отображением рекламы
                    на&nbsp;сайтах, в&nbsp;программах, продуктах или сервисах, которые принадлежат таким партнерам или
                    контролируются ими;<br/>

                    (ii) рекламодатели или другие Партнеры, которые отображают для Вас рекламу на&nbsp;Сайтах и/или
                    на&nbsp;Сервисах Администратора, а&nbsp;также такие Партнеры как поставщики информационных сервисов
                    или консультанты.<br/>

                    (iii) третьим лицам, в&nbsp;отношении которых произведена уступка прав или обязанностей, или новация
                    по&nbsp;соответствующему соглашению;<br/>

                    (iv) любому национальному и/или международному регулирующему органу, правоохранительным органам,
                    центральным или местным исполнительным органам власти, другим официальным или государственным
                    органам или судам, в&nbsp;отношении которых Администратор обязан предоставлять информацию
                    в&nbsp;соответствии с&nbsp;применимым законодательством по&nbsp;соответствующему запросу;<br/>

                    (v) третьим лицам, в&nbsp;случае если&nbsp;Вы выразили согласие на&nbsp;передачу Вашей Персональной
                    информации либо передача Персональной информации требуется для предоставления Вам соответствующего
                    Сервиса или выполнения определенного соглашения или договора, заключенного с&nbsp;Вами;<br/>

                    (vi) любому третьему лицу в&nbsp;целях обеспечения правовой защиты Администратора или третьих лиц
                    при нарушении Вами Пользовательского соглашения сервисов Администратора
                    (https://just-tests.ru/legal/rules), настоящей Политики или условий, регулирующих использование
                    отдельных Сервисов, либо в&nbsp;ситуации, когда существует угроза такого нарушения.<br/>

                    8. Где хранится и&nbsp;обрабатывается Ваша Персональная информация<br/>
                    Ваша Персональная информация будет храниться в&nbsp;Российской Федерации.<br/>

                    Для российских пользователей: Администратор осуществляет запись, систематизацию, накопление,
                    хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской
                    Федерации с&nbsp;использованием баз данных, находящихся на&nbsp;территории Российской Федерации.<br/>

                    Если Вы&nbsp;находитесь на&nbsp;территории, где для передачи Вашей Личной информации в&nbsp;другую
                    юрисдикцию требуется Ваше согласие, то&nbsp;используя Сайты или Сервисы, Вы&nbsp;даете
                    Администратору свое явное и&nbsp;однозначное согласие на&nbsp;такую передачу или хранение, и/или
                    обработку информации в&nbsp;других указанных юрисдикциях, включая Россию.<br/>

                    9. Как долго мы&nbsp;храним Вашу Персональную информацию<br/>
                    Администратор будет хранить Вашу Персональную информацию столько времени, сколько это необходимо для
                    достижения цели, для которой она была собрана, или для соблюдения требований законодательства
                    и&nbsp;нормативных актов.<br/>

                    Если Вы&nbsp;хотите, чтобы какая-либо Ваша Персональная информация была удалена из&nbsp;баз данных
                    Администратора, Вы&nbsp;можете самостоятельно удалить необходимую Персональную информацию
                    с&nbsp;использованием Вашей учетной записи или через интерфейс Сайтов и/или Сервисов (где это
                    применимо).<br/>

                    10. Ваши права<br/>
                    10.1 Какими правами&nbsp;Вы обладаете<br/>

                    В&nbsp;случае если это предусмотрено применимым законодательством, Вы&nbsp;имеете право
                    на&nbsp;доступ к&nbsp;Вашей Персональной информации, обрабатываемой Администратором
                    в&nbsp;соответствии с&nbsp;настоящей Политикой.<br/>

                    Если Вы&nbsp;считаете, что какая-либо информация, которую Администратор хранит о&nbsp;Вас,
                    некорректная или неполная, Вы&nbsp;можете войти в&nbsp;свою учетную запись и&nbsp;исправить Вашу
                    Персональную информацию самостоятельно.<br/>

                    Если это предусмотрено применимым законодательством, Вы&nbsp;имеете право:<br/>

                    ознакомиться с&nbsp;информацией, которую Администратор хранит о&nbsp;Вас;
                    требовать удаления Вашей Персональной информации или ее&nbsp;части, а&nbsp;также отзывать согласие
                    на&nbsp;обработку Вашей персональной информации;
                    требовать ограничений на&nbsp;обработку Вашей Персональной информации;
                    запросить копию Стандартных договорных условий, разрешающих передачу Вашей Персональной информации
                    в&nbsp;Россию и&nbsp;указанных в&nbsp;разделе 8&nbsp;настоящей Политики;
                    возражать против обработки Вашей Персональной информации, если это предусмотрено применимым
                    законодательством.
                    Администратор будет выполнять указанные запросы в&nbsp;соответствии с&nbsp;применимым
                    законодательством.<br/>

                    В&nbsp;случаях, предусмотренных применимым законодательством, Вы&nbsp;можете также обладать другими
                    правами, не&nbsp;указанными выше.<br/>

                    При использовании Вами Сервисов передача Персональной информации осуществляется c&nbsp;Вашего
                    согласия, выражающего свободную волю и&nbsp;Ваш интерес, (в&nbsp;частности, на&nbsp;основании
                    договора с&nbsp;Вами) и&nbsp;не&nbsp;является обязанностью по&nbsp;закону.<br/>

                    10.2 Как Вы&nbsp;можете реализовать свои права<br/>

                    Для осуществления вышеуказанных прав, пожалуйста, войдите в&nbsp;свою учетную запись,
                    а&nbsp;в&nbsp;случае отсутствия специальной функции в&nbsp;интерфейсе свяжитесь
                    с&nbsp;Администратором (см. раздел 13&nbsp;настоящей Политики).<br/>

                    Если Вы&nbsp;не&nbsp;удовлетворены тем, как Администратор обрабатывает Вашу Персональную информацию,
                    пожалуйста, сообщите нам, и&nbsp;мы&nbsp;рассмотрим Вашу претензию. Если
                    Вы&nbsp;не&nbsp;удовлетворены ответом Администратора, Вы&nbsp;имеете право подать жалобу
                    в&nbsp;компетентный орган.<br/>

                    11. Как мы&nbsp;используем файлы cookie и&nbsp;другие подобные технологии на&nbsp;Сайтах или при
                    использовании Вами Сервисов<br/>
                    11.1 Какие файлы cookie и&nbsp;зачем их&nbsp;использует Администратор<br/>

                    Файлы cookie&nbsp;&mdash; это небольшой фрагмент данных, который принимается и&nbsp;обрабатывается
                    устройством, которое&nbsp;Вы используете для доступа к&nbsp;Сайтам. Файлы cookie хранят
                    и&nbsp;отправляют обратно на&nbsp;Сайты информацию, которая помогает Вашей работе с&nbsp;Сайтами
                    и&nbsp;позволяет нам запоминать Ваши предпочтения по&nbsp;истечении времени, например, настройки
                    браузера или узнавать Вашу учетную запись. Для получения дополнительной информации о&nbsp;файлах
                    cookie, их&nbsp;описании, о&nbsp;целях использования и&nbsp;возможных способах управления ими,
                    следуйте нашей Политике использования файлов cookie. [https://just-tests.ru/legal/cookies_policy].<br/>

                    На&nbsp;Сайтах используются следующие типы файлов cookie:<br/>
                    строго необходимые файлы cookie&nbsp;/ технические файлы cookie: эти файлы cookie необходимы для
                    работы Сайтов и&nbsp;предоставления Вам Сервисов; кроме всего прочего, они позволяют Администратору
                    идентифицировать Ваше аппаратное и&nbsp;программное обеспечение, включая тип Вашего браузера;
                    статистические&nbsp;/ аналитические файлы cookie: эти файлы cookie позволяют распознавать
                    пользователей, подсчитывать их&nbsp;количество и&nbsp;собирать информацию, такую как произведенные
                    Вами операции на&nbsp;Сайтах и&nbsp;в&nbsp;Сервисах, включая информацию о&nbsp;посещенных Вами
                    веб-страницах и&nbsp;контенте, который&nbsp;Вы получаете;
                    технические файлы cookie: эти файлы cookie собирают информацию о&nbsp;том, как пользователи
                    взаимодействуют с&nbsp;Сайтами и/или Сервисами, что позволяет выявлять ошибки и&nbsp;тестировать
                    новые функции для повышения производительности Сайтов и&nbsp;Сервисов;
                    функциональные файлы cookie: эти файлы cookie позволяют предоставлять определенные функции, чтобы
                    облегчить использование Вами Сайтов, например, сохраняя Ваши предпочтения (такие как язык
                    и&nbsp;местоположение);
                    (сторонние) файлы отслеживания&nbsp;/ рекламные файлы cookie: эти файлы cookie собирают информацию
                    о&nbsp;пользователях, источниках трафика, посещенных страницах и&nbsp;рекламе, отображенной для Вас,
                    а&nbsp;также той, по&nbsp;которой&nbsp;Вы перешли на&nbsp;рекламируемую страницу. Они позволяют
                    отображать рекламу, которая может Вас заинтересовать, на&nbsp;основе анализа Персональной
                    информации, собранной о&nbsp;Вас. Они также используются в&nbsp;статистических
                    и&nbsp;исследовательских целях.<br/>
                    11.2 Как долго файлы cookie хранятся на&nbsp;Вашем устройстве<br/>

                    Администратор использует информацию, содержащуюся в&nbsp;файлах cookie только в&nbsp;указанных выше
                    целях, после чего собранные данные будут храниться на&nbsp;Вашем устройстве в&nbsp;течение периода,
                    который может зависеть от&nbsp;соответствующего типа файлов cookie, но&nbsp;не&nbsp;превышая срока,
                    необходимого для достижения их&nbsp;цели, после чего они будут автоматически удалены из&nbsp;Вашей
                    системы.<br/>

                    11.3 Кто еще имеет доступ к&nbsp;информации, содержащейся в&nbsp;файлах cookie<br/>

                    Персональная информация, собранная с&nbsp;помощью файлов cookie, размещенных на&nbsp;Вашем
                    устройстве, может быть передана и&nbsp;доступна Администратору и/или третьим лицам, указанным
                    в&nbsp;разделе 7&nbsp;настоящей Политики. Использование Персональной информации вне Сайтов
                    в&nbsp;рекламных целях, если таковые имеются, может быть предметом отдельных пользовательских
                    соглашений, доступных на&nbsp;веб-сайтах третьих лиц. Администратор и/или третьи лица могут также
                    предоставить Вам возможность отказаться от&nbsp;персонализации рекламы, которая может быть предметом
                    регулирования законодательства и&nbsp;правил, применимых к&nbsp;таким продуктам и&nbsp;предложениям.<br/>

                    При первом посещении Сайтов может быть запрошено Ваше согласие на&nbsp;использование файлов cookie.
                    Если после того, как&nbsp;Вы одобрили использование файлов cookie, Вы&nbsp;захотите изменить свое
                    решение, Вы&nbsp;сможете сделать это, удалив файлы cookie, хранящиеся в&nbsp;Вашем браузере (обычно
                    это можно сделать в&nbsp;настройках браузера&nbsp;&mdash; пожалуйста, обратитесь к&nbsp;руководству
                    по&nbsp;работе с&nbsp;браузером или сайту его разработчика). После этого может быть снова отображено
                    всплывающее окно, запрашивающее Ваше согласие, и&nbsp;Вы сможете сделать иной выбор. Если
                    Вы&nbsp;отказываетесь от&nbsp;использования файлов cookie, это может привести к&nbsp;тому, что
                    некоторые функции Сайтов будут Вам недоступны, и&nbsp;повлияет на&nbsp;возможность использования
                    Вами Cайтов. Вы&nbsp;также можете изменить настройки Вашего браузера, чтобы принимать или отклонять
                    по&nbsp;умолчанию все файлы cookie или файлы cookie с&nbsp;определенных сайтов, включая Сайты
                    Администратора. Если Вы&nbsp;одобрили использование файлов cookie на&nbsp;одном из&nbsp;Сайтов
                    Администратора, мы&nbsp;будем считать, что использование файлов cookie на&nbsp;всех Cайтах было
                    одобрено Вами.<br/>

                    Администратор также может использовать веб-маяки (пиксельные теги) для доступа к&nbsp;файлам cookie,
                    ранее размещенным на&nbsp;Вашем устройстве, для следующих целей:<br/>

                    (i) определение Ваших действий на&nbsp;Сайтах и&nbsp;в&nbsp;процессе использования Сервисов путем
                    доступа и&nbsp;использования файлов cookie, хранящихся на&nbsp;Вашем устройстве;<br/>

                    (ii) сбор статистической информации, связанной с&nbsp;работой Сайтов, Сервисов или продуктов, утилит
                    и&nbsp;предложений Администратора.<br/>
                    12. Обновление настоящей Политики<br/>
                    В&nbsp;настоящую Политику могут быть внесены изменения. Администратор имеет право вносить изменения
                    по&nbsp;своему усмотрению, в&nbsp;том числе, но&nbsp;не&nbsp;ограничиваясь, в&nbsp;случаях, когда
                    соответствующие изменения связаны с&nbsp;изменениями в&nbsp;применимом законодательстве,
                    а&nbsp;также когда соответствующие изменения связаны с&nbsp;изменениями в&nbsp;работе Сайтов
                    и&nbsp;Сервисов.<br/>

                    Администратор обязуется не&nbsp;вносить существенных изменений, не&nbsp;налагать дополнительных
                    обременений или ограничений Ваших прав, установленных настоящей Политикой без Вашего уведомления.
                    Вы&nbsp;будете уведомлены о&nbsp;таких изменениях. Соответствующие уведомления могут быть отображены
                    на&nbsp;Сайте или в&nbsp;Сервисах (например, через всплывающее окно или баннер) до&nbsp;того, как
                    такие изменения вступят в&nbsp;силу, или могут быть отправлены Вам по&nbsp;другим каналам связи
                    (например, по&nbsp;электронной почте, если&nbsp;Вы предоставили нам Ваши контактные данные).<br/>
                    13. Вопросы и&nbsp;предложения<br/>
                    Администратор приветствует Ваши вопросы и&nbsp;предложения, касающиеся исполнения или изменения
                    настоящей Политики. Пожалуйста, направьте Ваш запрос на&nbsp;электронный почтовый адрес
                    support@just-tests.ru. Вы&nbsp;также можете воспользоваться этим адресом для направления запросов
                    о&nbsp;реализации Ваших прав или жалоб относительно некорректности Вашей Персональной информации или
                    незаконности ее&nbsp;обработки.
                </div>
            </div>
        </section>
    )
}

export  default Confidential;