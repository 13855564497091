import HeaderSecondary from "../header-secondary/header-secondary";
import {Link} from 'react-router-dom';

const Legal = () => {
    return (
        <section>
            <HeaderSecondary
                title='Юридическая информация'
                description=''/>
            <div className="pt-2 pb-4">
                <div className="container py-4">
                    <div className="pb-2">
                        <Link to={'/legal/confidential'}>Политика конфиденциальности</Link>
                    </div>
                    <div className="pb-2">
                        <Link to={'/legal/rules'}>Пользовательское соглашение сервиса Just-Tests.ru</Link>
                    </div>
                    <div className="pb-2">
                        <Link to={'/legal/cookies_policy'}>Политика использования файлов cookie</Link>
                    </div>
                    <div className="pb-2">
                        <Link to={'/legal/mobile_agreement_gims'}>Лицензионное соглашение на использование программы
                            «Билеты ГИМС 2023» для мобильных устройств.</Link>
                    </div>
                    <div className="pb-2">
                        <Link to={'/legal/mobile_agreement_gtn'}>Лицензионное соглашение на использование программы
                            «Билеты ГТН 2023» для мобильных устройств.</Link>
                    </div>
                    <div className="pb-2">
                        <Link to={'/legal/mobile_agreement_pdd'}>Лицензионное соглашение на использование программы
                            «Билеты ПДД 2023» для мобильных устройств.</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Legal;