import React, { Component } from 'react'
import './secondary-card.css'

export default class SecondaryCard extends Component {
    render() {
        const { name, nbrOfTickets, description, image } = this.props;
        return (
            <div className="secondary-card">
                {
                    image &&
                    <div className="secondary-card__image">
                        <img src={process.env.REACT_APP_API_URL + image}></img>
                    </div>
                }
                <div className="p-4">
                    <h3 className="secondary-card__header">{name}</h3>
                    {
                        description &&
                        <div className="secondary-card__description">{description}</div>
                    }
                    {
                        nbrOfTickets &&
                        <div className="d-flex">
                            <div className="secondary-card__additional">
                                <div className="bold">{nbrOfTickets}</div>
                                <div className="light">билетов</div>
                            </div>
                        </div>
                    }
                </div>
            </div >
        )
    }
}